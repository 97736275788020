import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P86-E1",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page86/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page86/E1/2.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P86-E2",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 12,
    exerciseKey: "img/FriendsPlus/Page86/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page86/E2/28.jpg" }],
      [{ url: "img/FriendsPlus/Page86/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E2/2.jpg" },
        { url: "img/FriendsPlus/Page86/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/4.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/5.jpg" },
        { url: "img/FriendsPlus/Page86/E2/6.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/7.jpg" },
        { url: "img/FriendsPlus/Page86/E2/8.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/9.jpg" },
        { url: "img/FriendsPlus/Page86/E2/10.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/11.jpg", input: 6, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page86/E2/12.jpg" },
        { url: "img/FriendsPlus/Page86/E2/13.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/14.jpg" },
        { url: "img/FriendsPlus/Page86/E2/15.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/16.jpg" },
        { url: "img/FriendsPlus/Page86/E2/17.jpg" },
        { url: "img/FriendsPlus/Page86/E2/18.jpg" },
        { url: "img/FriendsPlus/Page86/E2/19.jpg", input: 9, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/20.jpg", input: 10, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/21.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page86/E2/22.jpg" },
        { url: "img/FriendsPlus/Page86/E2/23.jpg", input: 11, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/24.jpg" },
        { url: "img/FriendsPlus/Page86/E2/25.jpg", input: 12, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E2/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page86/E2/27.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P86-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page86/E3/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page86/E3/25.jpg" }],
      [{ url: "img/FriendsPlus/Page86/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E3/2.jpg" },
        { url: "img/FriendsPlus/Page86/E3/3.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page86/E3/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E3/5.jpg" },
        { url: "img/FriendsPlus/Page86/E3/6.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page86/E3/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E3/8.jpg" },
        { url: "img/FriendsPlus/Page86/E3/9.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page86/E3/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E3/11.jpg" },
        { url: "img/FriendsPlus/Page86/E3/12.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page86/E3/13.jpg" },
        { url: "img/FriendsPlus/Page86/E3/14.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page86/E3/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E3/16.jpg" },
        { url: "img/FriendsPlus/Page86/E3/17.jpg", input: 2 },
        { url: "img/FriendsPlus/Page86/E3/18.jpg" },
        { url: "img/FriendsPlus/Page86/E3/19.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page86/E3/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page86/E3/21.jpg" },
        { url: "img/FriendsPlus/Page86/E3/22.jpg", input: 2 },
        { url: "img/FriendsPlus/Page86/E3/23.jpg" },
        { url: "img/FriendsPlus/Page86/E3/24.jpg", input: 3, isCorrect: true },
      ],
    ],
  },
};

export default json;
