import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P11-E1",
    audio: "/Audios/Track 2/audio.mp3",
    video: "",
    component: DesignUnderLine,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the words. Look of the pictures. Fill in the correct circle.<headphone name='02' src='Audios/Track 2/tieude.mp3'></headphone>",
        color: "#224192",
      },
    ],
    // component: DesignUnderLine1,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page11/E1/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page11/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E1/2.jpg" },
        { url: "img/FriendsPlus/Page11/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page11/E1/4.jpg" },
        { url: "img/FriendsPlus/Page11/E1/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page11/E1/6.jpg" },
        { url: "img/FriendsPlus/Page11/E1/7.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E1/10.jpg" },
        { url: "img/FriendsPlus/Page11/E1/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page11/E1/12.jpg" },
        { url: "img/FriendsPlus/Page11/E1/13.jpg", input: 2 },
        { url: "img/FriendsPlus/Page11/E1/14.jpg" },
        { url: "img/FriendsPlus/Page11/E1/15.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E1/18.jpg" },
        { url: "img/FriendsPlus/Page11/E1/19.jpg", input: 3 },
        { url: "img/FriendsPlus/Page11/E1/20.jpg" },
        { url: "img/FriendsPlus/Page11/E1/21.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E1/22.jpg" },
        { url: "img/FriendsPlus/Page11/E1/23.jpg", input: 3 },
        { url: "img/FriendsPlus/Page11/E1/24.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P11-E2",
    audio: "/Audios/Track 3/audio.mp3",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page11/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen. Put a check (✓) or a cross (✗) in the box.<headphone name='03' src='Audios/Track 3/tieude.mp3'></headphone>",
        color: "#224192",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page11/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E2/2.jpg" },
        { url: "img/FriendsPlus/Page11/E2/3.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E2/5.jpg" },
        { url: "img/FriendsPlus/Page11/E2/6.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/7.jpg" }],

      [
        { url: "img/FriendsPlus/Page11/E2/8.jpg" },
        { url: "img/FriendsPlus/Page11/E2/9.jpg", input: 2 },
        { url: "img/FriendsPlus/Page11/E2/10.jpg" },
        { url: "img/FriendsPlus/Page11/E2/11.jpg", input: 3, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/12.jpg" }],

      [
        { url: "img/FriendsPlus/Page11/E2/13.jpg" },
        { url: "img/FriendsPlus/Page11/E2/14.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E2/15.jpg" },
        { url: "img/FriendsPlus/Page11/E2/16.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/17.jpg" }],
    ],
    questions: [],
  },
};
export default json;
