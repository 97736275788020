import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "WB3V2-U3-P26-E1",
    audio: "",
    video: "",
    component: D1,
    maxLength: 1,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page26/E1/16.jpg" }],
      [{ url: "img/FriendsPlus/Page26/E1/17.jpg" }],
      [{ url: "img/FriendsPlus/Page26/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page26/E1/2.jpg" },
        { url: "img/FriendsPlus/Page26/E1/3.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page26/E1/4.jpg" },
        { url: "img/FriendsPlus/Page26/E1/5.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page26/E1/6.jpg" },
        { url: "img/FriendsPlus/Page26/E1/7.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page26/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page26/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page26/E1/10.jpg" },
        { url: "img/FriendsPlus/Page26/E1/11.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page26/E1/12.jpg" },
        { url: "img/FriendsPlus/Page26/E1/13.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page26/E1/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page26/E1/15.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 3",
    id: "WB3V2-U3-P26-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/answerKey.png",
    inputSize: 100,
    maxLength: 5,
    // titleImage: "img/FriendsPlus/Page6/E1/title.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page26/E2/1.jpg' />",
        color: "",
      },
    ],

    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin: 0px 100px'>
           <div  style='margin-bottom: 30px'> 
              Where is the (1)<span style='color:gray; text-decoration:underline'>teddy bear</span>? 
              The teddy bear isn’t in the (2)#. 
           </div> 
           <div style='margin-bottom: 30px'>
              Is it on the (3)#? No. Is it under the 
              (4)#?
           </div>
              Yes.
              The teddy bear is un der the slide! Linh is happy.
          </div>
          <div style='margin:30px 200px; border:2px solid green; border-radius: 15px; text-align: center; padding: 5px 0px'> slide&emsp;&emsp;<span style='color:gray;text-decoration:line-through'>teddy bear</span>&emsp;&emsp;pool&emsp;&emsp;chair  </div>
         `,
        answer: ["pool", "chair", "slide"],
      },
    ],
  },
};

export default json;
