import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P64-E1",
    audio: "Audios/Track 16/audio.mp3",
    video: "",
    component: DesignUnderLine,
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/answerKey.png",
    totalInput: 3,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page64/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page64/E1/b.jpg",
          audioUrl: "Audios/Track 16/tieude.mp3",
        },
        { url: "img/FriendsPlus/Page64/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page64/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page64/E1/2.jpg" },
        { url: "img/FriendsPlus/Page64/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page64/E1/4.jpg" },
        { url: "img/FriendsPlus/Page64/E1/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page64/E1/6.jpg" },
        { url: "img/FriendsPlus/Page64/E1/7.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page64/E1/8.jpg" }],
      [
        { url: "img/FriendsPlus/Page64/E1/9.jpg" },
        { url: "img/FriendsPlus/Page64/E1/10.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page64/E1/11.jpg" },
        { url: "img/FriendsPlus/Page64/E1/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page64/E1/13.jpg" },
        { url: "img/FriendsPlus/Page64/E1/14.jpg", input: 2 },
      ],
      [{ url: "img/FriendsPlus/Page64/E1/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page64/E1/16.jpg" },
        { url: "img/FriendsPlus/Page64/E1/17.jpg", input: 3 },
        { url: "img/FriendsPlus/Page64/E1/18.jpg" },
        { url: "img/FriendsPlus/Page64/E1/19.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page64/E1/20.jpg" },
        { url: "img/FriendsPlus/Page64/E1/21.jpg", input: 3 },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P64-E2",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
    inputSize: 113,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page64/E2/Key/answerKey.png",

    // titleImage: "img/FriendsPlus/Page64/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page64/E2/2.jpg' />",
        color: "",
      },
    ],
    maxLength: 5,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page64/E2/1.jpg' />
            <div style='margin:50px 50px;line-height: 68px;'>
              <b>1.</b> She can<u style="color: gray;">&emsp;read&emsp;</u><br>
              <b>2.</b> She can#.<br>
              <b>3.</b> She can#.
            </div>
          </div>
                `,
        answer: ["talk", "dance"],
      },
    ],
  },
};

export default json;
