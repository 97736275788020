import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P54-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page54/E1/Key/answerKey.png",
    video: "",

    isHiddenCheck: true,
    component: D1,
    // component: DesignUnderLine1,
    totalInput: 2,
    titleImage: "",
    maxLength: 6,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page54/E1/23.jpg" }],
      [{ url: "img/FriendsPlus/Page54/E1/22.jpg" }],
      [{ url: "img/FriendsPlus/Page54/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page54/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/3.jpg",
          input: true,
          answer: "skirt",
        },
        { url: "img/FriendsPlus/Page54/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page54/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page54/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/7.jpg",
          input: true,
          answer: "friend",
        },
        { url: "img/FriendsPlus/Page54/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page54/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page54/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/11.jpg",
          input: true,
          answer: "name",
        },
        { url: "img/FriendsPlus/Page54/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page54/E1/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page54/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/15.jpg",
          input: true,
          answer: "shirt",
        },
        { url: "img/FriendsPlus/Page54/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page54/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page54/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/19.jpg",
          input: true,
          answer: "blue",
        },
        { url: "img/FriendsPlus/Page54/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page54/E1/21.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P54-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page54/E2/Key/answerKey.png",
    inputSize: 70,
    maxLength: 3,
    // titleImage: "img/FriendsPlus/Page53/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page54/E2/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex ;margin: 0px 50px'>
          <div style='line-height: 68px; margin-right: 50px'>
            <div><b>1.</b> Jane is wearing a pink shirt. <u style="color: gray;">&emsp;yes&emsp;</u></div>
            <div><b>2.</b> Jane is wearing a blue shirt.#</div>
            <div><b>3.</b> Her friend is Sam.#</div>
            <div><b>4.</b> Sue is wearing a blue shirt.#</div>
            <div><b>5.</b> Sue is wearing a black skirt.#</div>
            <div><b>6.</b> Jane has a toy.#</div>
          </div>
          <img src='img/FriendsPlus/Page54/E2/2.jpg' />

        </div>
        `,
        answer: ["no", "no", "yes", "no", "no"],
      },
    ],
  },
};

export default json;
