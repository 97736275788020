import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB3V2-U2-P21-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		isHiddenCheck: true,
		totalInput: 5,
		exerciseKey: 'img/FriendsPlus/Page21/E1/Key/answerKey.png',
		questionImage: [
			[{ url: 'img/FriendsPlus/Page21/E1/a.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page21/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/3.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/5.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/6.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page21/E1/7.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/8.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/9.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page21/E1/10.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page21/E1/11.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page21/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/13.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/14.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/15.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page21/E1/16.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/17.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page21/E1/18.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page21/E1/19.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/20.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/21.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page21/E1/22.jpg', input: 4 },
			],
			[
				{ url: 'img/FriendsPlus/Page21/E1/23.jpg' },

				{ url: 'img/FriendsPlus/Page21/E1/24.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page21/E1/25.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/26.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/27.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page21/E1/28.jpg' },
				{ url: 'img/FriendsPlus/Page21/E1/29.jpg', input: 6 },
				{ url: 'img/FriendsPlus/Page21/E1/30.jpg', input: 6 },
				{ url: 'img/FriendsPlus/Page21/E1/31.jpg', input: 6, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/32.jpg', input: 6, isCorrect: true },
				{ url: 'img/FriendsPlus/Page21/E1/33.jpg' },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB3V2-U2-P21-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: '',
		inputSize: 170,
		titleImage: 'img/FriendsPlus/Page21/E2/1.jpg',
		textAlign: 'center',
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='margin-top: 20px; max-width: 70%; display: flex; flex-wrap: wrap; justify-content: center; gap: 20px; border:2px solid green; padding:5px; border-radius: 10px'>
          <span>red</span><span>yellow</span><span>blue</span><span>green</span>
          <span>pink</span><span>black</span><span>orange</span><span>white</span>
        </div>
        <div style='max-width: 70%; display:flex; margin-top: 50px; gap: 30px; flex-wrap: wrap;'>
            <div>pen:#</div>
            <div>book:#</div>
            <div>eraser:#</div>
            <div>bag:#</div>
            <div>pencil:#</div>
        </div>
        `,
				answer: [],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB3V2-U2-P21-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: '',
		inputSize: 420,
		titleImage: 'img/FriendsPlus/Page21/E3/1.jpg',
		questionImage: [],
		hideBtnFooter: true,
		questions: [
			{
				title: `
       <div style='display:flex; flex-wrap: wrap; gap: 30px; justify-content: center;'>
          <img  src='img/FriendsPlus/Page21/E3/3.jpg'>
          <div style='margin-top: 20px; display: flex; flex-direction: column;'>
            <span>That is #.</span>
            <span>It's #.</span>
            <span>That is #.</span>
            <span>It's #.</span>
            <span>That #.</span>
            <span>It's #.</span>
          </div>
       </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
