import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "WB3V2-U6-P45-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page45/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: " <img src='img/FriendsPlus/Page45/E1/2.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "44px",
              width: 80,
              height: 80,
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "365px",
              width: 70,
              height: 80,
              borderRadius: "1300",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "700px",
              width: 100,
              height: 80,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "43px",
              width: 90,
              height: 90,
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "367px",
              width: 100,
              height: 70,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "700px",
              width: 85,
              height: 75,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "298px",
              left: "543px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "392px",
              left: "543px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "212px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 8
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "307px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 9
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "405px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 10
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "500px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 12
        ],
        answers: ["0-5", "1-3", "2-4"],
        initialValue: [],
      },
      Layout: `
			<img src='img/FriendsPlus/Page45/E1/1.jpg' />
			<input id='0' type= 'boxMatch' />
			<input id='1' type= 'boxMatch' />
			<input id='2' type= 'boxMatch' />
			<input id='3' type= 'boxMatch' />
			<input id='4' type= 'boxMatch' />
			<input id='5' type= 'boxMatch' />
     



      
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "WB3V2-U6-P45-E2",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page45/E2/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: " <img src='img/FriendsPlus/Page45/E2/1.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "110px",
              width: 130,
              height: 150,
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "290px",
              left: "160px",
              width: 120,
              height: 140,
              borderRadius: "50px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "160px",
              left: "770px",
              width: 100,
              height: 180,
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "460px",
              width: 120,
              height: 130,
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "230px",
              left: "460px",
              width: 134,
              height: 130,
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "460px",
              width: 125,
              height: 125,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "298px",
              left: "543px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "392px",
              left: "543px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "212px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 8
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "307px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 9
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "405px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 10
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "500px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 12
        ],
        answers: ["0-4", "1-3", "2-5"],
        initialValue: [],
      },
      Layout: `
			<img src='img/FriendsPlus/Page45/E2/2.jpg' />
			<input id='0' type= 'boxMatch' />
			<input id='1' type= 'boxMatch' />
			<input id='2' type= 'boxMatch' />
			<input id='3' type= 'boxMatch' />
			<input id='4' type= 'boxMatch' />
			<input id='5' type= 'boxMatch' />
     



      
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P45-E3",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    isHiddenCheck:true,
    exerciseKey: "img/FriendsPlus/Page45/E3/Key/answerKey.png",
    inputSize: 50,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page45/E3/7.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex ; margin: 20px 0px 0px 50px'>
          	<div style='margin-right: 100px'>
							<div><img src='img/FriendsPlus/Page45/E3/1.jpg' /></div>
							<div style='display:flex'>
								<div style='margin:30px 20px 0px 20px'> ###</div>
								<div><img src='img/FriendsPlus/Page45/E3/3.jpg' /> </div>
							</div>
						</div>
          	<div>
							<div><img src='img/FriendsPlus/Page45/E3/2.jpg' /></div>
							<div style='display:flex'>
								<div style='margin:30px 20px 0px 20px'> ####</div>
								<div><img src='img/FriendsPlus/Page45/E3/4.jpg' /> </div>
							</div>
						</div>
					</div>
          	<div style='margin-left: 50px '>
							<div><img src='img/FriendsPlus/Page45/E3/5.jpg' /></div>
							<div style='display:flex'>
								<div style='margin:30px 20px 0px 20px'> ####</div>
								<div><img src='img/FriendsPlus/Page45/E3/6.jpg' /> </div>
							</div>
						</div>
        `,
        answer: ["m", "o", "m", "l", "i", "o", "n", "k", "i", "t", "e"],
      },
    ],
  },
};

export default json;
