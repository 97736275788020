import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  4: {
    // Exercise num
    unit: "Review 1",
    id: "WB3V2-R1-P29-E4",
    audio: "",
    video: "",
    component: D1,
    // textAlign: "center",
    maxLength: 17,
    exerciseKey: "img/FriendsPlus/Page29/E4/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page29/E4/11.jpg" }],
      [{ url: "img/FriendsPlus/Page29/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page29/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page29/E4/3.jpg",
          input: true,
          answer: "How old are you?",
        },
      ],
      [{ url: "img/FriendsPlus/Page29/E4/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page29/E4/5.jpg" },
        {
          url: "img/FriendsPlus/Page29/E4/6.jpg",
          input: true,
          answer: "That is my kite.",
        },
      ],
      [{ url: "img/FriendsPlus/Page29/E4/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page29/E4/8.jpg" },
        {
          url: "img/FriendsPlus/Page29/E4/9.jpg",
          input: true,
          answer: "What's your name?",
        },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Review 1",
    id: "WB3V2-RV1-P29-E5",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page29/E5/Key/answerKey.png",
    inputSize: 80,

    textAlign: "center",
    // titleImage: "img/FriendsPlus/Page6/E4/title.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page29/E5/1.jpg' />",
        color: "",
      },
    ],
    checkUppercase: true,
    questionImage: [],

    questions: [
      {
        title: `
      <div style=" display: flex ">
          <div><img src='img/FriendsPlus/Page29/E5/2.jpg' /></div>
          
          <div style='margin-left: 20px'>
            <div style='margin-top:13px ;display:flex'>
              <b>1.</b> 
              <div style='border:1px solid green;border-radius: 5px;margin-left:5px;padding:2px 50px;'>their&emsp;&emsp;his&emsp;&emsp;her</div>
            </div>
            <div style='margin: 10px 20px'>This is #classroom. <br> #ball is on #head.<br> #book is in #bag.</div>
            <div style='margin-top:60px ;display:flex'>
              <b>2.</b> What about you?
              <div style='border:1px solid green;border-radius: 5px;margin-left:5px;padding:2px 50px;'>our&emsp;&emsp;its</div>
            </div>
            <div style='margin: 10px 20px'>Look at #classroom.<br>#board is on the wall.</div>

            
          </div>
      </div>
        `,
        answer: ["their", "Her", "her", "His", "his", "our", "Its"],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Review 1",
    id: "WB3V2-RV1-P29-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page29/E5/Key/answerKey.png",
    inputSize: 80,
    hideBtnFooter: true,
    // textAlign: "center",
    // titleImage: "img/FriendsPlus/Page6/E4/title.jpg",

    stylesTextInput: {
      borderBottom: "dash",
    },
    checkUppercase: true,
    questionImage: [],
    mywork: [
      {
        text: "My favorite story in Units 1-3 is",
      },
      { text: "My favorite song in Units 1-3 is" },
      { text: "My favorite unit in Units 1-3 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page29/images/1.jpg" }],
    colorBG: "#01a450",
    wordColor: "#dbecc2",
    boxColor: "red",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='0' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='0' width='660px'></input></div>
                
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
