import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P71-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page71/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page71/E1/1.jpg' >",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "165px",
              width: 235,
              height: 185,
              // border: "1px solid #2eb6e1",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "350px",
              left: "165px",
              width: 225,
              height: 185,
              // border: "1px solid #2eb6e1",
            },
          }, //1

          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "815px",
              width: 245,
              height: 185,
              // border: "1px solid #2eb6e1",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "350px",
              left: "815px",
              width: 235,
              height: 185,
              // border: "1px solid #2eb6e1",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "450px",
              width: 170,
              height: 60,
              // border: "1px solid #2eb6e1",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "460px",
              width: 200,
              height: 60,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "270px",
              left: "460px",
              width: 200,
              height: 60,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //6

          {
            boxMatchStyle: {
              position: "absolute",
              top: "370px",
              left: "460px",
              width: 205,
              height: 60,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //7
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "290px",
          //     left: "665px",
          //     width: 30,
          //     height: 30,
          //     border: "1px solid #2eb6e1",
          //     borderRadius: "50px",
          //   },
          // }, //8
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "353px",
          //     left: "705px",
          //     width: 120,
          //     height: 30,
          //     // border: "1px solid #2eb6e1",
          //     borderRadius: "50px",
          //   },
          // }, //9
        ],
        answers: ["0-5", "1-7", "2-4", "3-6"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page71/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />

          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />

          <input id='7' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P71-E2",
    audio: "",
    video: "",
    component: D1,
    totalInput: 3,
    fontSize: 30,
    textAlign:"center",
    exerciseKey: "img/FriendsPlus/Page71/E2/Key/answerKey.png",
    titleImage: "",
    maxLength: 16,
    inputHeight: 33,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page71/E2/13.jpg" }],
      [{ url: "img/FriendsPlus/Page71/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page71/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page71/E2/3.jpg",
          input: true,
          answer: "No, you may not.",
        },
        { url: "img/FriendsPlus/Page71/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page71/E2/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page71/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page71/E2/7.jpg",
          input: true,
          answer: "Yes, you may.",
        },
        { url: "img/FriendsPlus/Page71/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page71/E2/9.jpg",
          input: true,
          answer: "No, you may not.",
        },
        { url: "img/FriendsPlus/Page71/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page71/E2/11.jpg" }],
    ],
  },
};

export default json;
