import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 10',
		id: 'WB3V2-U10-P74-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		totalInput: 3,
		exerciseKey: 'img/FriendsPlus/Page74/E1/Key/answerKey.png',
		titleImage: '',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page74/E1/16.jpg' }],
			[{ url: 'img/FriendsPlus/Page74/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page74/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page74/E1/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page74/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page74/E1/5.jpg', input: 1, isCorrect: true },
			],
			[{ url: 'img/FriendsPlus/Page74/E1/6.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page74/E1/7.jpg' },
				{ url: 'img/FriendsPlus/Page74/E1/8.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page74/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page74/E1/10.jpg', input: 2 },
			],
			[{ url: 'img/FriendsPlus/Page74/E1/11.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page74/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page74/E1/13.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page74/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page74/E1/15.jpg', input: 3 },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 10',
		id: 'WB3V2-U10-P74-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page74/E2/Key/answerKey.png',
		inputSize: 100,
		titleImage: 'img/FriendsPlus/Page74/E2/3.jpg',
		// titleQuestion: [{ num: "", title: "", color: "" }],
		maxLength: 5,
		textAlign: 'center',
		questionImage: [],
		questions: [
			{
				title: `
        <img src='img/FriendsPlus/Page74/E2/4.jpg' />

         <div style='display:flex'>
            <div>
              <img src='img/FriendsPlus/Page74/E2/1.jpg' />
              <div style='margin: 10px 40px'>
                The painting is <u style="color: gray;">&emsp;old&emsp;</u>.
              </div>
            </div>
            <div style='margin-left:40px'>
              <img src='img/FriendsPlus/Page74/E2/2.jpg' />
              <div style='margin: 10px 40px'>
                The gift shop is #.
              </div>
            </div>

         </div>
        `,
				answer: ['great'],
			},
		],
	},
}

export default json
