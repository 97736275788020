import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  3: {
    // Exercise num
    unit: "Review 3",
    id: "WB3V2-R3-P69-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page69/E3/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page69/E3/31.jpg" }],
      [{ url: "img/FriendsPlus/Page69/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E3/2.jpg" },
        { url: "img/FriendsPlus/Page69/E3/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E3/6.jpg" },
        { url: "img/FriendsPlus/Page69/E3/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page69/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E3/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E3/10.jpg" },
        { url: "img/FriendsPlus/Page69/E3/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page69/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E3/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E3/14.jpg" },
        { url: "img/FriendsPlus/Page69/E3/15.jpg", input: 2 },
        { url: "img/FriendsPlus/Page69/E3/16.jpg" },
        { url: "img/FriendsPlus/Page69/E3/17.jpg", input: 3 },
        { url: "img/FriendsPlus/Page69/E3/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E3/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E3/20.jpg" },
        { url: "img/FriendsPlus/Page69/E3/21.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E3/22.jpg" },
        { url: "img/FriendsPlus/Page69/E3/23.jpg", input: 3 },
        { url: "img/FriendsPlus/Page69/E3/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E3/25.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E3/26.jpg" },
        { url: "img/FriendsPlus/Page69/E3/27.jpg", input: 2 },
        { url: "img/FriendsPlus/Page69/E3/28.jpg" },
        { url: "img/FriendsPlus/Page69/E3/29.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E3/30.jpg" },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Review 3",
    id: "WB3V2-R3-P69-E4",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    maxLength: 22,
    exerciseKey: "img/FriendsPlus/Page69/E4/Key/answerKey.png",
    inputSize: 280,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page69/E4/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
						<div style='line-height:60px;margin-left:30px'>
								<div >
										<b> 1.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>can’t</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>bike.</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>a</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>He</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>ride</span>
										<span style='margin-left:30px'><u style="color: gray;"> He can’t ride a bike.&emsp;</u></span>
								</div>
								<div >
										<b> 2.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>run.</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>can</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>She</span>
										<span style='margin-left:149px'>#</span>
								</div>
								<div >
										<b> 3.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>soccer.</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>She</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>play</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>can’t</span>
										<span style='margin-left:28px'>#</span>
								</div>
								<div >
										<b> 4.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>can</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>She</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>catch.</span>
										<span style='margin-left:125px'>#</span>
								</div>
								
						</div>
        `,
        answer: ["She can run.", "She can't play soccer.", "She can catch."],
      },
    ],
  },
  5: {
    unit: "Review 3",
    id: "WB3V2-R3-P69-E5",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page68/E1/Key/answerKey.png",
    checkDuplicated: true,
    isHiddenCheck: true,
    hideBtnFooter: true,
    mywork: [
      {
        text: "My favorite story in Units 7-9 is",
      },
      { text: "My favorite song in Units 7-9 is" },
      { text: "My favorite unit in Units 7-9 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page69/images/1.jpg" }],
    colorBG: "#ed1b24",
    wordColor: "#fef6f3",
    boxColor: "red",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='0' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='0' width='660px'></input></div>
                
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
