import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 11',
		id: 'WB3V2-U11-P77-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		totalInput: 3,
		exerciseKey: 'img/FriendsPlus/Page77/E1/Key/answerKey.png',
		titleImage: '',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page77/E1/20.jpg' }],
			[{ url: 'img/FriendsPlus/Page77/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page77/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page77/E1/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page77/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page77/E1/5.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page77/E1/6.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page77/E1/7.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page77/E1/8.jpg' },
				{ url: 'img/FriendsPlus/Page77/E1/9.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page77/E1/10.jpg' },
				{ url: 'img/FriendsPlus/Page77/E1/11.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page77/E1/12.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page77/E1/13.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page77/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page77/E1/15.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page77/E1/16.jpg' },
				{ url: 'img/FriendsPlus/Page77/E1/17.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page77/E1/18.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page77/E1/19.jpg' }],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 11',
		id: 'WB3V2-U11-P77-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page77/E2/Key/answerKey.png',
		inputSize: 150,
		maxlength: 9,
		isHiddenCheck: true,
		textAlign: 'center',
		// titleImage: "img/FriendsPlus/Page77/E2/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page77/E2/1.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <img src='img/FriendsPlus/Page77/E2/2.jpg' />
        <div style='display:flex;margin:10px 60px'>
          <div style='margin:; line-height:50px'>
            <b>1.</b> <u style="color: gray;">&emsp;There’s&emsp;</u>a cabinet.<br>
            <b>2.</b> #a shelf.<br>
            <b>3.</b> #three pillows.
          </div>
          <div style='margin: 0px 100px; line-height:50px'>
            <b>4.</b> #two beds.<br>
            <b>5.</b> #a lot of toys.<br>
            <b>6.</b> #a rug.
          </div>

        </div>
        `,
				answer: ["There's", 'There are', 'There are', 'There are', "There's"],
			},
		],
	},
}

export default json
