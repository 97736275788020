import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P59-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page59/E1/Key/answerKey.png",
    component: MatchDots,
    titleImage: "<img src='img/FriendsPlus/Page53/E1/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page59/E1/1.jpg'>",
        color: "#23408f",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "133px",
              width: 110,
              height: 110,
              // border: "2px solid red"
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "174px",
              left: "133px",
              width: 110,
              height: 110,
              // border: "2px solid red"
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "294px",
              left: "133px",
              width: 110,
              height: 110,
              // border: "2px solid red"
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "535px",
              width: 95,
              height: 32,
              // border: "2px solid red"
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "525px",
              width: 86,
              height: 32,
              // border: "2px solid red"
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "140px",
              left: "530px",
              width: 90,
              height: 32,
              // border: "2px solid red"
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "200px",
              left: "535px",
              width: 105,
              height: 32,
              // border: "2px solid red"
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "265px",
              left: "546px",
              width: 130,
              height: 32,
              // border: "2px solid red"
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "320px",
              left: "535px",
              width: 105,
              height: 32,
              // border: "2px solid red"
            },
          },
          //8

          //16
        ],
        answers: ["1-3", "0-4", "2-5", "2-7", "0-6", "1-8"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page59/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P59-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    inputSize: 48,
    textAlign: "center",
    maxLength: 2,
    exerciseKey: "img/FriendsPlus/Page59/E2/Key/answerKey.png",
    // textareaStyle: { width: 760 },
    // titleImage: "img/FriendsPlus/Page59/E5/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page59/E2/7.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex; margin-top: 15px'>
            <div>
              <img src='img/FriendsPlus/Page59/E2/1.jpg' />
              <div style='margin-left: 40px'>#ee</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page59/E2/2.jpg' />
              <div style='margin-left: 40px'>#ab</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page59/E2/3.jpg' />
              <div style='margin-left: 40px'>#um</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page59/E2/4.jpg' />
              <div style='margin-left: 40px'>#ess</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page59/E2/5.jpg' />
              <div style='margin-left: 40px'>#ayon</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page59/E2/6.jpg' />
              <div style='margin-left: 40px'>#uck</div>
            </div>
          </div>
          `,
        answer: ["tr", "cr", "dr", "dr", "cr", "tr"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P59-E3",
    audio: "Audios/Track 14/audio.mp3",
    video: "",
    component: T6,
    // hideBtnFooter: true,
    inputSize: 130,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 7,
    exerciseKey: "img/FriendsPlus/Page59/E3/Key/answerKey.png",
    // textareaStyle: { width: 760 },
    // titleImage: "img/FriendsPlus/Page59/E5/1.jpg",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and write. <headphone name='14' src='Audios/Track 14/tieude.mp3' ></headphone>",
        color: "#233f94",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='border: 1px solid orange; padding: 5px 10px; border-radius: 10px; margin:0px 10px;text-align: center'>
            drum&emsp;&emsp;
            <span style='color:gray; text-decoration:line-through'>train</span>
            &emsp;&emsp;crab&emsp;&emsp;truck&emsp;&emsp;crayons&emsp;&emsp;dress&emsp;&emsp;tree&emsp;&emsp;crayons
          </div>
          <div style='display: flex; margin: 10px 20px'>
            <div style='line-height: 50px'>
              A <sup>1</sup><u style="color: gray;">&emsp;train&emsp;</u> and a <sup>2</sup>#,<br>
              A <sup>3</sup># and a <sup>4</sup>#.<br>
              I draw with my<sup>5</sup>#,<br>
              And i have fun.<br>
              A girl in a <sup>6</sup>#,<br>
              A bird in a <sup>7</sup>#,<br>
              Get your <sup>8</sup>#,<br>
              And draw with me.

            </div>
            <img src='img/FriendsPlus/Page59/E3/2.jpg' />

          </div>
          `,
        answer: [
          "truck",
          "crab",
          "drum",
          "crayons",
          "dress",
          "tree",
          "crayons",
        ],
      },
    ],
  },
};

export default json;
