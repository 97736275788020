import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB3V2-U4-P32-E1",
    audio: "Audios/Track 7/audio.mp3",

    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page32/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match. <headphone name='07' src='Audios/Track 7/tieude.mp3'></headphone>",
        color: "#224192",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "170px",
              width: 250,
              height: 200,
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "940px",
              width: 220,
              height: 220,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "165px",
              width: 220,
              height: 180,
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "920px",
              width: 200,
              height: 200,
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "543px",
              width: 85,
              height: 35,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "115px",
              left: "543px",
              width: 85,
              height: 35,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "298px",
              left: "543px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "392px",
              left: "543px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "212px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 8
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "307px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 9
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "405px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 10
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "500px",
          //     left: "450px",
          //     width: 85,
          //     height: "35px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 12
        ],
        answers: ["0-6", "1-5", "2-7", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex; margin-bottom:40px'>
        <div style=' margin-right: 200px'><img src='img/FriendsPlus/Page32/E1/1.jpg' /></div>
        <div><input id='0'  type='boxMatch' /></div>
        <div><input id='1'  type='boxMatch' /></div>  
        <div style=' margin-right: 176px'>
          <div style='margin-bottom: 50px;font-size: 30px'>Eric</div>
          <div style='font-size: 30px'>Penny</div>
          <div><input id='2'  type='boxMatch' /></div>
          <div><input id='3'  type='boxMatch' /></div> 
         </div>
        <div><img src='img/FriendsPlus/Page32/E1/2.jpg' /></div>
      </div>       
      <div style='display:flex;margin-bottom:40px'>
        <div style=' margin-right: 200px'><img src='img/FriendsPlus/Page32/E1/3.jpg' /></div>
        <div><input id='4'  type='boxMatch' /></div>
        <div><input id='5'  type='boxMatch' /></div>
        <div style=' margin-right: 176px'>
          <div style='margin-bottom: 50px;font-size: 30px'>Terry</div>
          <div style='font-size: 30px'>Susie</div>
          <div><input id='6'  type='boxMatch' /></div>
          <div><input id='7'  type='boxMatch' /></div>
        </div>
        <div><img src='img/FriendsPlus/Page32/E1/4.jpg' /></div>
      </div>       
      
      </div> 
     



      
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P32-E2",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    maxLength: 4,
    exerciseKey: "img/FriendsPlus/Page32/E2/Key/answerKey.png",
    inputSize: 70,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page32/E2/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div><img src='img/FriendsPlus/Page32/E2/2.jpg' /></div>
            <div style='margin: 30px 0px 0px 100px'>
              <div style='margin-bottom: 100px'> The <u style="color: gray;">&emsp;dog&emsp;</u>  brown.</div>
              <div style='margin-bottom: 100px'> The #is blue.</div>
              <div style='margin-bottom: 90px'> The #is yellow.</div>
              <div> The #is orange.</div>

            </div>
          </div>
        `,
        answer: ["fish", "bird", "cat"],
        initialValue: [""],
      },
    ],
  },
};

export default json;
