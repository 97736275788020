import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P87-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page87/E1/Key/answerKey.png",
    inputSize: 350,
    checkUppercase: true,
    maxLength: 27,
    titleImage: "img/FriendsPlus/Page87/E1/1.jpg",
    // titleQuestion: [{ num: '4', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1.</b> this is anh. <span style='margin-left: 30px'><u style="color: gray;">&emsp;This is Anh&emsp;</u> </span>
            <br>
            <b>2.</b> her teacher is miss lan. <span style='margin-left: 30px'> # </span>
            <br>
            <b>3.</b> his name’s trung. <span style='margin-left: 30px'> # </span>
            <br>
            <b>4.</b> trung and mai are cousins. <span style='margin-left: 30px'> # </span>
            <br>
            <b>5.</b> vinh is her little brother. <span style='margin-left: 30px'> # </span>
            <br>
        </div>
        `,
        answer: [
          "Her teacher is Miss Lan.",
          "His name's Trung.",
          "Trung and Mai are cousins.",
          "Vinh is her little brother.",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P87-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page84/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page87/E2/29.jpg'>",
        color: "",
      },
    ],
    question: {
      Write: {
        underlineStyle: { borderBottom: "none" },
        inputStyle: {
          color: "black",
          textAlign: "center",
          width: "40px",
          height: "40px",
          border: "1px solid gray",
          borderRadius: "7px",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 400,
          color: "",
          fontSize: 26,
          borderRadius: "20px",
        },
        selectWordStyle: { border: "2px solid #2eb6e1" },
        limitSelect: 3,
        listWords: [
          "tables",
          "chairs",
          "board",
          "cabinet",
          "drawers",
          "computer",
          "window",
          "door",
          "pencil_cases",
          "boys",
          "girls",
          "teachers",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style='max-width: 700px; display: flex; flex-wrap: wrap; gap: 10px 20px; 
                  padding: 10px; border: 1px solid rgb(96, 118, 169); border-radius: 10px;'>
          <input id='0' type='Circle' />
          <input id='1' type='Circle' />
          <input id='2' type='Circle' />
          <input id='3' type='Circle' />
          <input id='4' type='Circle' />
          <input id='5' type='Circle' />
          <input id='6' type='Circle' />
          <input id='7' type='Circle' />
          <input id='8' type='Circle' />
          <input id='9' type='Circle' />
          <input id='10' type='Circle' />
          <input id='11' type='Circle' />
        </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P87-E3",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    inputSize: 250,
    titleImage: "img/FriendsPlus/Page87/E3/3.jpg",
    questionImage: [],
    questions: [
      {
        title: `
         <div style="display: flex">\
            <img src='img/FriendsPlus/Page87/E3/1.jpg' />
         
            <div style='margin: 20px 30px; line-height: 50px'>
              This is my classroom.<br>
              <u style="color: gray;">&emsp;This is the window.&emsp;</u><br>
              This is the#.<br>
              That#.<br>
              There are the#.<br>
              Those#.<br>
            </div>
         </div>
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
  4: {
    unit: "Unit 12",
    id: "WB3V2-U12-P87-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E4/4.jpg" }]],
  },
};

export default json;
