import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  4: {
    // Exercise num
    unit: "Review 2",
    id: "WB3V2-R2-P49-E1",
    audio: "",
    video: "",
    component: D1,
    isHiddenCheck: true,
    totalInput: 7,
    exerciseKey: "img/FriendsPlus/Page49/E1/Key/answerKey.png",
    fontSize: 28,
    inputHeight: 33,
    maxLength: 12,
    textAlign: "center",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page49/E1/22.jpg' />",
        color: "",
      },
    ],
    checkUppercase: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page49/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page49/E1/3.jpg",
          input: true,
          answer: "I don't have",
        },
        { url: "img/FriendsPlus/Page49/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page49/E1/7.jpg",
          input: true,
          answer: "I have",
        },
        { url: "img/FriendsPlus/Page49/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page49/E1/11.jpg",
          input: true,
          answer: "I have",
        },
        { url: "img/FriendsPlus/Page49/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page49/E1/15.jpg",
          input: true,
          answer: "I don't have",
        },
        { url: "img/FriendsPlus/Page49/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page49/E1/19.jpg",
          input: true,
          answer: "I have",
        },
        { url: "img/FriendsPlus/Page49/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/21.jpg" }],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P49-E2",
    audio: "",
    textAlign: "center",
    video: "",
    component: T6,
    hideBtnFooter: true,
    inputSize: 140,
    titleImage: "img/FriendsPlus/Page49/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],

    questionImage: [],
    questions: [
      {
        title: `
        <div style='border: 1px solid orange;text-align:center;border-radius: 10px;padding:2px 30px;'>
          long&emsp;&emsp;short&emsp;&emsp;curly&emsp;&emsp;straight&emsp;&emsp;
          black<br>brown&emsp;&emsp;red&emsp;&emsp;blue&emsp;&emsp;green&emsp;&emsp;
          tall&emsp;&emsp;thin
        </div>
        <div style='margin-top:20px'>#has#eyes#has#hair.</div>
        <div style='margin-top:20px'>#has#eyes#has#hair.</div>
        <div style='margin-top:20px'>#has#eyes#has#hair.</div>
        <div style='margin-top:20px'>#has#eyes#has#hair.</div>
        
        `,
        answer: [],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Review 2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    inputSize: 80,
    hideBtnFooter: true,
    // textAlign: "center",
    // titleImage: "img/FriendsPlus/Page6/E4/title.jpg",

    stylesTextInput: {
      borderBottom: "dash",
    },
    checkUppercase: true,
    questionImage: [],
    mywork: [
      {
        text: "My favorite story in Units 4-6 is",
      },
      { text: "My favorite song in Units 4-6 is" },
      { text: "My favorite unit in Units 4-6 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page49/images/1.jpg" }],
    colorBG: "#f25822",
    wordColor: "#fef5d6",
    boxColor: "red",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='0' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='0' width='660px'></input></div>
                
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
