import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P78-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page78/E1/Key/answerKey.png",
    component: MatchDots, 
    // titleImage: ,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page78/E1/1.jpg'>.",
        color: "#23408f",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "403px",
              left: "48px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "403px",
              left: "117px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "323px",
              left: "117px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },

          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "29px",
              left: "117px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "141px",
              left: "117px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },

          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "253px",
              left: "117px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },

          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "403px",
              left: "826px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },

          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "253px",
              left: "826px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "323px",
              left: "826px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "79px",
              left: "826px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "79px",
              left: "893px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "253px",
              left: "893px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "403px",
              left: "893px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "253px",
              left: "398px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "253px",
              left: "690px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "323px",
              left: "252px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },
          //16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "323px",
              left: "544px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },//17
          {
            boxMatchStyle: {
              position: "absolute",
              top: "29px",
              left: "48px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },//18
          {
            boxMatchStyle: {
              position: "absolute",
              top: "216px",
              left: "48px",
              width: 15,
              height: 15,
              borderRadius:"50%",
              // border: "2px solid"
            },
          },//19
        ],
        answers: [
          "0-18"
,"0-1"
,"1-2"
,"15-2"
,"15-16"
,"16-8"
,"12-6"
,"6-8"
,"11-12"
,"10-11"
, "10-9"
, "7-9"
, "14-7"
, "13-14"
, "13-5"
, "4-5"
, "3-4"
, "17-3"
        ],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page78/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
          <input id='17' type= 'boxMatch' />
          <input id='18' type= 'boxMatch' />
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P78-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page78/E2/Key/answerKey.png",
    inputSize: 130,
    maxLength: 9,
    titleImage: "img/FriendsPlus/Page78/E2/4.jpg",
    titleQuestion: [{ num: "", title: "", color: "" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src='img/FriendsPlus/Page78/E2/1.jpg' />
          <div style='margin:70px 20px 0px 20px'>
              <div style='margin-bottom:93px'>e<u style="color: gray;">leven&emsp;</u></div>
              <div style='margin-bottom:90px'>t#</div>
              <div style='margin-bottom:94px'>t#</div>
              <div style='margin-bottom:91px'>f#</div>
              <div style='margin-bottom:0px'>f#</div>
          </div>
          <img src='img/FriendsPlus/Page78/E2/2.jpg' />
          <div style='margin:70px 20px 0px 20px'>
              <div style='margin-bottom:93px'>s#</div>            
              <div style='margin-bottom:90px'>s#</div>            
              <div style='margin-bottom:94px'>e#</div>            
              <div style='margin-bottom:91px'>n#</div>            
              <div style='margin-bottom:0px'>t#</div>            
          </div>
          <img src='img/FriendsPlus/Page78/E2/3.jpg' />
        </div>
        `,
        answer: [
          "welve",
          "hirteen",
          "ourteen",
          "ifteen",
          "ixteen",
          "eventeen",
          "ighteen",
          "ineteen",
          "wenty",
        ],
      },
    ],
  },
};

export default json;
