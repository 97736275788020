import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P31-E1",
    audio: "Audios/Track 6/audio.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page31/E1/Key/answerKey.png",
    inputSize: 120,
    maxLength: 8,
    fontSize: 18,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write. <headphone name='06' src='Audios/Track 6/tieude.mp3'></headphone>",
        color: "#224192",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div style='margin-right: 70px'>
              <div><img src='img/FriendsPlus/Page31/E1/1.jpg' /></div>
              <div>It's a <span style='color:gray; text-decoration:underline'>giraffe</span>.</div>
              <div>It's <span style='color:gray; text-decoration:underline'>tall</span>.</div>
            </div>
            <div>
              <div><img src='img/FriendsPlus/Page31/E1/2.jpg' /></div>
              <div>It's a #.</div>
              <div>It's #.</div>
            </div>
          </div>
          <div style='margin-top:20px'>
              <div><img src='img/FriendsPlus/Page31/E1/3.jpg' /></div>
              <div>It's an #.</div>
              <div>It's #.</div>
          </div>
        `,
        answer: ["monkey", "small", "elephant", "big"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P31-E2",
    audio: "",
    video: "",
    component: D1,
    // textAlign: "center",
    maxLength: 22,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page31/E2/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page31/E2/9.jpg" }],
      [{ url: "img/FriendsPlus/Page31/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page31/E2/3.jpg",
          input: true,
          answer: "I don't like elephants",
        },
      ],
      [{ url: "img/FriendsPlus/Page31/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page31/E2/6.jpg",
          input: true,
          answer: "like monkeys",
        },
        { url: "img/FriendsPlus/Page31/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page31/E2/8.jpg",
          input: true,
          answer: "I don't like giraffes",
        },
      ],
    ],
  },
};

export default json;
