/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

function HeadPhone({ name, src }) {
  const audio = new Audio(src);

  React.useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      <img
        src="img/headphone.jpg"
        alt=""
        style={{
          width: "60px",
          cursor: src ? "url(img/volume.png) 12 12, auto" : "",
        }}
        onClick={() => {
          audio.play();
        }}
      />
      <span
        style={{
          fontSize: 16,
          position: "absolute",
          top: "55%",
          left: "70%",
          transform: "translate(-50%, -50%)",
          color: "#ffffff",
        }}
      >{` ${name}`}</span>
    </div>
  );
}

export default HeadPhone;
