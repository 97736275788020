import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 8',
		id: 'WB3V2-U8-P57-E1',
		audio: '',
		video: '',
		component: T6,
		maxLength: 19,
		exerciseKey: 'img/FriendsPlus/Page57/E1/Key/answerKey.png',
		inputSize: 300,
		checkUppercase: true,
		// titleImage: "img/FriendsPlus/Page56/E4/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page57/E1/6.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
				<div style='margin-left: 100px'><img src='img/FriendsPlus/Page57/E1/7.jpg' /></div>
        <div style='display:flex'>
        		<div style='margin-top: 0px'><img src='img/FriendsPlus/Page57/E1/1.jpg' /></div>
						<div>
								<div style='margin: 0px 0px 60px 20px ;display:flex'>
									<div>
										<b> 1.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>you</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>What</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>doing</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>are</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>?</span>
										<br>&emsp; dancing with Jim
									</div>
									<div style='margin-left:10px'><u style="color: gray;">&emsp;What are you doing?&emsp;</u><br><u style="color: gray;">&emsp;I’m dancing with Jim.&emsp;</u></div>
								</div>
								<div style='margin: 0px 0px 60px 20px ;display:flex'>
									<div>
										<b> 2.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>doing</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>she</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>What’s</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>?</span>
										<br>&emsp; eating
									</div>
									<div style='margin-left:77px'>#<br>#</div>
								</div>
								<div style='margin: 0px 0px 65px 20px ;display:flex'>
									<div>
										<b> 3.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>she</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>?</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>doing</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>What’s</span>
										<br>&emsp; sleeping
									</div>
									<div style='margin-left:77px'>#<br>#</div>
								</div>
								<div style='margin: 0px 0px 76px 20px ;display:flex'>
										<div>
											<b> 4.</b> 
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>are</span> 
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>?</span>
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>you</span>
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>What</span>
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>doing</span>
											<br>&emsp; talking
										</div>
										<div style='margin-left:30px'>#<br>#</div>
								</div>
								<div style='margin-left:20px;display:flex'>
										<div>
											<b> 5.</b> 
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>he</span> 
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>?</span>
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>What’s</span>
											<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>doing</span>
											<br>&emsp; singing
										</div>
										<div style='margin-left:90px'>#<br>#</div>
								</div>
						</div>
				</div>
        `,
				answer: [
					"What's she doing?",
					"She's eating. / She is eating.",
					"What's she doing?",
					"She's sleeping. / She is sleeping.",
					'What are you doing?',
					"I'm talking. / I am talking.",
					"What's he doing?",
					"He's singing. / He is singing.",
				],
			},
		],
	},
}

export default json
