import { Form } from 'antd'
import moment from 'moment'
import 'moment/locale/vi'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Button, Container, Row } from 'reactstrap'
import {
	BigPlayButton,
	ClosedCaptionButton,
	ControlBar,
	CurrentTimeDisplay,
	ForwardControl,
	Player,
	ReplayControl,
} from 'video-react'
import FooterModal from '../../FooterModal'
import TitleQuestion from '../../TitleQuestion'
import HintBox from '../../Utilities/HintBox'
import Circle, { calculateResultCircle } from './Circle'
import StarModal from './StarModal'
import Write, { calculateResultWrite, toFormValues } from './Write'

moment.locale('vi')

function Circle_Write(props) {
	const [form] = Form.useForm()
	const [state, setState] = React.useState({
		booleanArray: [],
		isDisabledRetry: true,
		isDisabledSubmit: true,
	})
	const [Question, setQuestion] = React.useState(null)
	const [modalStar, setModalStar] = React.useState(false)
	//
	React.useEffect(() => {
		const clonedProps = JSON.parse(JSON.stringify(props))
		const question = clonedProps.question
		//initialCircle
		question.Circle.isDoing = true
		question.Circle.userAnswers = Array.from(question.Circle.initialValue)
		//initialWrite
		question.Write.isDoing = true
		question.Write.userAnswers = Array.from(question.Write.initialValue)
		form.resetFields()
		form.setFieldsValue(toFormValues(question.Write.userAnswers)) // nhập giá trị cho form
		//
		const audioUrl = clonedProps.audio
		const videoUrl = clonedProps.video
		//update
		setState((prevState) => ({ ...prevState, audioUrl, videoUrl }))
		setQuestion(question)
	}, [form, props])
	// hiển thị ngôi sao
	const onModalStar = () => {
		setModalStar(true)
		setTimeout(() => {
			setModalStar(false)
		}, 3000)
	}
	//
	const onSubmit = React.useCallback(() => {
		const { Circle, Write } = Question
		//Circle
		Circle.isDoing = false //
		Circle.resultCircle = calculateResultCircle(
			Circle.userAnswers,
			Circle.answers,
			Circle.listWords,
			Circle.limitSelect
		)
		//Write
		Write.isDoing = false //
		const values = form.getFieldsValue()
		Write.userAnswers = Object.values(values)
		Write.resultWrite = calculateResultWrite(
			Write.userAnswers,
			Write.answers,
			Write.checkUppercase
		)
		//State
		const { star, resultString, percent, booleanArray } = calculateResult(
			Circle.resultCircle.booleanArray,
			Write.resultWrite.booleanArray
		)
		const studentAnswer = {
			Write: Write.userAnswers,
			Circle: Circle.userAnswers,
		}
		const params = {
			score: percent,
			unit: props.unit,
			results: resultString,
			exerciseId: props.id,
			sreatedDate: moment().format(),
			studentAnswer: JSON.stringify(studentAnswer),
		}
		props.postAnswerToApi(params)
		//update
		setState((pre) => ({
			...pre,
			isDisabledRetry: false,
			isDisabledSubmit: true,
			booleanArray,
			star,
		}))
		setQuestion((pre) => ({ ...pre, Circle, Write }))
		onModalStar()
	}, [Question, form, props])

	const onRetry = React.useCallback(() => {
		const { Circle, Write } = Question
		//initialCircle
		Circle.isDoing = true //
		Circle.userAnswers = Array.from(Circle.initialValue)
		//initialWrite
		Write.isDoing = true
		Write.userAnswers = Array.from(Write.initialValue)
		form.resetFields()
		form.setFieldsValue(toFormValues(Write.userAnswers)) // nhập giá trị cho form
		//initialState
		const booleanArray = []
		//update
		setState((pre) => ({ ...pre, booleanArray, isDisabledRetry: true }))
		setQuestion((pre) => ({ ...pre, Circle, Write }))
	}, [Question, form])

	const transform = React.useCallback(
		(node, index) => {
			if (
				node.type === 'tag' &&
				node.name === 'input' &&
				node.attribs.type === 'Circle'
			) {
				//render tag input Circle
				const id_circle = node.attribs.id
				return (
					<Circle
						key={index}
						id={id_circle}
						data={Question.Circle}
						setData={(data) => setQuestion((pre) => ({ ...pre, Circle: data }))}
					/>
				)
			}
			if (node.type === 'tag' && node.name === 'hintbox') {
				const { id } = node.attribs
				return props.hintBox.map((v, i) => {
					if (id !== `${i}`) return null
					const { src, borderColor, width, position, arrow } = v
					return (
						<HintBox
							key={i}
							src={src}
							borderColor={borderColor}
							width={width}
							position={position}
							arrow={arrow}
						/>
					)
				})
			}
			if (node.type === 'tag' && node.name === 'input') {
				//render tag input
				const { id, type, rows, ...elementStyle } = node.attribs
				return (
					<Write
						key={index}
						id={id}
						data={Question.Write}
						type={type}
						rows={rows}
						elementStyle={elementStyle}
					/>
				)
			}
		},
		[Question]
	)
	//
	const checkEnableSubmit = React.useCallback(() => {
		//
		setQuestion((question) => {
			//check Write
			const formValues = form.getFieldsValue()
			const isWriteDone = Object.values(formValues).every((item) => item)
			//check Circle
			const { Circle } = question
			const isCircleDone = Circle.userAnswers.length >= Circle.answers.length
			const isSubmit = isWriteDone && isCircleDone
			//
			setState((pre) => ({ ...pre, isDisabledSubmit: !isSubmit }))
			return question
		})
	}, [form])
	//
	const renderContent = React.useCallback(() => {
		const { Layout } = Question
		return (
			<div
				style={{ fontSize: 24, marginBottom: 50 }}
				onClick={checkEnableSubmit}
			>
				{ReactHtmlParser(Layout, { transform })}
			</div>
		)
	}, [Question, checkEnableSubmit, transform])
	//
	if (!Question) return null

	return (
		<Container>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<div>
					<Row>
						<TitleQuestion titleQuestion={props.titleQuestion} />
					</Row>
					{state.videoUrl && (
						<div class='d-flex justify-content-center' style={props.videoStyle}>
							<div style={{ width: '35vw', marginBottom: 50 }}>
								<Player>
									<BigPlayButton position='center' />
									<source src={props.video} type='video/mp4' default />

									<track
										kind='captions'
										src={props.videoSub}
										srcLang='en'
										label='English'
									/>
									<ControlBar autoHide={false}>
										<ReplayControl seconds={10} order={1.1} />
										<ForwardControl seconds={10} order={1.2} />
										<CurrentTimeDisplay order={4.1} />
										<ClosedCaptionButton order={7} />
									</ControlBar>
								</Player>
							</div>
						</div>
					)}
					<Row
						style={{
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'start',
						}}
					>
						<Form
							autoComplete='off'
							form={form}
							// onFinish={onFinish}
							onValuesChange={checkEnableSubmit}
						>
							{renderContent()}
						</Form>
					</Row>
					<StarModal isModal={modalStar} star={state.star} />
				</div>
			</div>

			{/* {testButton({ Question, setQuestion, form })} */}

			<FooterModal
				onRetry={onRetry}
				onSubmit={onSubmit}
				audioUrl={state.audioUrl}
				result={state?.booleanArray}
				studentAnswer={state.studentAnswer}
				exerciseKey={props.exerciseKey}
				isDisabledRetry={state.isDisabledRetry}
				isDisabledSubmit={state.isDisabledSubmit}
				hideBtnFooter={props.hideBtnFooter} //not answer
			/>
		</Container>
	)
}

export default Circle_Write

//tính kết quả
function calculateResult(...array) {
	const booleanArray = [].concat(...array)
	const correctAnswer = booleanArray.filter((x) => x === true).length
	const percent = correctAnswer
		? parseInt((correctAnswer * 100) / booleanArray.length)
		: 0 //correctAnswer = 0 => percent = 0
	const star = percent / 20
	const resultString = `${correctAnswer}/${booleanArray.length}`

	return { star, resultString, percent, correctAnswer, booleanArray }
}
// test
function testButton({ Question, setQuestion, form }) {
	return (
		<div>
			<Button
				onClick={() => {
					setQuestion((pre) => {
						const { Circle, Write } = pre
						Circle.userAnswers = Array.from(Circle.answers)
						//
						Write.userAnswers = Array.from(Write.answers)
						form.resetFields()
						form.setFieldsValue(toFormValues(Write.userAnswers)) // nhập giá trị cho form
						return { ...pre, Circle, Write }
					})
				}}
			>
				điền đáp án test
			</Button>
			<Button
				onClick={() => {
					console.log('🚀 ~ Circle.userAnswers: ', Question.Circle.userAnswers)
					const values = form.getFieldsValue()
					const Write_userAnswers = Object.values(values)
					console.log('🚀 ~ Write_userAnswers', Write_userAnswers)
				}}
			>
				log userAnswers
			</Button>
		</div>
	)
}
