import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P19-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page19/E1/Key/answerKey.png",
    component: MatchDots,
    titleImage: "<img src='img/FriendsPlus/Page19/E1/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page19/E1/1.jpg'>",
        color: "#23408f",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "350px",
              width: 47,
              height: 62,
              // border: "2px solid red"
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "460px",
              width: 47,
              height: 62,
              // border: "2px solid red"
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "571px",
              width: 47,
              height: 62,
              // border: "2px solid red"
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "683px",
              width: 47,
              height: 52,
              // border: "2px solid red"
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "793px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "244px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "350px",
              width: 47,
              height: 52,
              // border: "2px solid red"
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "460px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "571px",
              width: 47,
              height: 52,
              // border: "2px solid red"
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "683px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "793px",
              width: 47,
              height: 52,
              // border: "2px solid red"
            },
          },
          //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "244px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "350px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "219px",
              left: "460px",
              width: 47,
              height: 52,
              // border: "2px solid red"
            },
          },
          //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "571px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "683px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "793px",
              width: 47,
              height: 67,
              // border: "2px solid red"
            },
          },
          //16
        ],
        answers: ["13-6", "13-8", "3-8", "10-3"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page19/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P19-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page19/E2/Key/answerKey.png",
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Page19/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page19/E2/1.jpg'>",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "100px",
              width: 140,
              height: 162,
              // border: "2px solid red"
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "420px",
              width: 125,
              height: 127,
              // border: "2px solid red"
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "700px",
              width: 140,
              height: 122,
              // border: "2px solid red"
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "285px",
              left: "100px",
              width: 140,
              height: 102,
              // border: "2px solid red"
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "285px",
              left: "420px",
              width: 125,
              height: 120,
              // border: "2px solid red"
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "720px",
              width: 170,
              height: 90,
              // border: "2px solid red"
            },
          },
          //5

          //16
        ],
        answers: ["0-1", "1-5", "3-4", "2-4"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page19/E2/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P19-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page19/E3/Key/answerKey.png",
    inputSize: 40,
    maxLength: 1,
    fontSize: 30,
    isHiddenCheck: true,
    // titleImage: 'img/FriendsPlus/Page19/E2/1.jpg',
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page19/E3/10.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
           <div style='margin: 30px 100px'>
              <div style='display:flex'>
                <div style='margin-right: 250px'><img src='img/FriendsPlus/Page19/E3/1.jpg' /></div>
                <div><img src='img/FriendsPlus/Page19/E3/2.jpg' /></div>

              </div>
              <div style='display:flex'>
                <div style='margin:0px 150px 0px 30px'><img src='img/FriendsPlus/Page19/E3/11.jpg' /> <img src='img/FriendsPlus/Page19/E3/5.jpg' /></div>
                <div>###<img src='img/FriendsPlus/Page19/E3/6.jpg'/> </div>

              </div>
              <div style='display:flex'>
                <div style='margin-right: 250px'><img src='img/FriendsPlus/Page19/E3/3.jpg' /></div>
                <div><img src='img/FriendsPlus/Page19/E3/4.jpg' /></div>

              </div>
              <div style='display:flex'>
                <div style='margin:0px 260px 0px 30px'>###<img src='img/FriendsPlus/Page19/E3/7.jpg'/> </div>
                <div>####<img src='img/FriendsPlus/Page19/E3/8.jpg'/> </div>

              </div>
            </div> 
          `,
        answer: ["c", "a", "t", "d", "o", "g", "d", "e", "s", "k"],
      },
    ],
  },
};

export default json;
