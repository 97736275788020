import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P61-E1",
    audio: "",
    video: "",
    component: T6,
    // hideBtnFooter: true,
    inputSize: 130,
    textAlign: "center",
    maxLength: 9,
    exerciseKey: "img/FriendsPlus/Page61/E1/Key/answerKey.png",
    // textareaStyle: { width: 760 },
    // titleImage: "img/FriendsPlus/Page59/E5/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page61/E1/5.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
	      <div style='display:flex'>
	      <img src='img/FriendsPlus/Page61/E1/1.jpg' />
	      <div style='margin-left:10px'>
	        <span style='background: pink ;padding: 5px 10px; border-radius: 10px'>
	          wear
	        </span>
	        <br>He’s <u style="color: gray;">&emsp;wearing&emsp;</u> <br>while boots.
	      </div>
	      <img style='margin-left:86px' src='img/FriendsPlus/Page61/E1/2.jpg' />
	      <div style='margin-left:10px'>
	        <span style='background: pink ;padding: 5px 10px; border-radius: 10px'>
	          eat
	        </span>
	        <br>She’s#<br> carrots.
	      </div>
	    </div>
	    <div style='display:flex; margin-top: 30px'>
	      <img src='img/FriendsPlus/Page61/E1/3.jpg' />
	      <div style='margin-left:10px'>
	        <span style='background: pink ;padding: 5px 10px; border-radius: 10px'>
	          read
	        </span>
	        <br> My sister is<br># a book.
	      </div>
	      <img style='margin-left:40px' src='img/FriendsPlus/Page61/E1/4.jpg' />
	      <div style='margin-left:10px'>
	        <span style='background: pink ;padding: 5px 10px; border-radius: 10px'>
	          listen
	        </span>
	        <br>I’m# to<br> music.
	      </div>
	    </div>
	        `,
        answer: ["eating", "reading", "listening"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P61-E2",
    audio: "",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 4,
    exerciseKey: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page61/E2/21.jpg" }],
      [{ url: "img/FriendsPlus/Page61/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page61/E2/2.jpg" },
        { url: "img/FriendsPlus/Page61/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/4.jpg" },
        { url: "img/FriendsPlus/Page61/E2/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/6.jpg" },
        { url: "img/FriendsPlus/Page61/E2/7.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/8.jpg" },
        { url: "img/FriendsPlus/Page61/E2/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page61/E2/11.jpg" },
        { url: "img/FriendsPlus/Page61/E2/12.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/13.jpg" },
        { url: "img/FriendsPlus/Page61/E2/14.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/15.jpg" },
        { url: "img/FriendsPlus/Page61/E2/16.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/17.jpg" },
        { url: "img/FriendsPlus/Page61/E2/18.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page61/E2/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page61/E2/20.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P61-E3",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "",
    inputSize: 280,
    maxLength: 19,
    // titleImage: "img/FriendsPlus/Page55/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page61/E3/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display: flex'>
                  <img src='img/FriendsPlus/Page55/E3/2.jpg' />
                <div style='margin-left: 30px; line-height:45px'>
                    <div><u style="color: gray;">I’m at a party. I’m listening to music.</u></div>
                    <div>I’m at a party. I’m#.</div>
                    <div>I’m #.</div>
                    <div>My mom is #.</div>
                    <div>My dad is #.</div>
                    <div> #.</div>
                </div>
            </div>
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P61-E4",
    audio: "",
    // exerciseKey: "img/FriendsPlus/Page60/E1/Key/answerKey.png",
    video: "",
    recorder: true,
    component: UI,
    // component: DesignUnderLine1,
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page61/E4/1.jpg" }],
    ],
  },
};

export default json;
