import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import DrawColor from '../../components/ExcerciseTypes/DrawColor'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB3V2-U4-P35-E1',
		audio: '',
		video: '',
		component: T6,
		// isHiddenCheck:true,
		exerciseKey: 'img/FriendsPlus/Page35/E1/Key/answerKey.png',
		inputSize: 222,
		maxLength: 20,
		// titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page35/E1/1.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex; margin: 0px 30px'> 
            <img src='img/FriendsPlus/Page35/E1/2.jpg' />
            <div style='margin-left:20px'>
              <div style='margin: 20px 0px 20px 0px'> <u style="color: gray;">&emsp;like lions&emsp;</u>.</div>
              <div style='margin: 30px 0px 35px 0px'> #.</div>
              <div style='margin-bottom: 35px'> #.</div>
              <div> #.</div>
            </div>
        </div>
        `,
				answer: ["don't like giraffes", "don't like birds", 'like monkeys'],
				initialValue: ['like lions'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		id: 'WB3V2-U3-P35-E2',
		audio: '',
		video: '',
		component: DrawColor,
		// exerciseKey: "img/FriendsPlus/Page15/E2/Key/answerKey.png",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page35/E2/2.jpg'>",
				color: '',
			},
		],
		hideBtnFooter: true,
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				listStyle: {
					// normal: {},
					AnimalsYouLike: {
						padding: 3,
						border: 'solid 2px',
						borderRadius: '50%',
						borderColor: '#4285F4',
					},
					"AnimalsYouDon'tLike": { borderBottom: '2px solid #4285F4' },
					// square: { padding: 3, border: "solid 2px", borderColor: "#4285F4" },
				},
				limitSelect: 1,
				listWords: [
					'lions',
					'monkeys',
					'cats',
					'goats',
					'elephants',
					'giraffes',
					'dogs',
					'birds',
				],
				answers: {},
				initialValue: {},
			},
			Layout: `
      <div style='border:2px solid rgb(255 102 0); padding: 20px 40px; margin:20px; border-radius: 10px'>
        <span style='margin-right:50px'><input id='0' type='Circle' /></span>
        <span style='margin-right:50px'><input id='1' type='Circle' /></span>
        <span style='margin-right:50px'><input id='2' type='Circle' /></span>
        <span style='margin-right:50px'><input id='3' type='Circle' /></span>
        <span style='margin-right:50px'><input id='4' type='Circle' /></span>
        <span style='margin-right:50px'><input id='5' type='Circle' /></span>
        <span style='margin-right:50px'><input id='6' type='Circle' /></span>
        <input id='7' type='Circle' />
      </div>
      `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB3V2-U4-P35-E3',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// exerciseKey: "img/FriendsPlus/Page35/E1/Key/answerKey.png",
		inputSize: 300,
		maxLength: 20,
		// titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page35/E3/1.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page35/E3/3.jpg'>
            <div style='margin:30px 10px;line-height:45px'>
              I like#.<br>
              I <input id='1' width='250'>.<br>
              I <input id='2' width='290'>.<br>
              I don’t like#.<br>
              I <input id='3' width='250'>.<br>
              I <input id='4' width='250'>.<br>
            </div>
          </div>

        `,
				answer: ['', '', '', '', '', ''],
				initialValue: ['like lions'],
			},
		],
	},
	4: {
		unit: 'Unit 5',
		id: 'WB3V2-U5-P35-E4',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		question: [],
		questionImage: [[{ url: 'img/FriendsPlus/Page35/E4/2.jpg' }]],
	},
}

export default json
