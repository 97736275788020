import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3-U5-P39-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page39/E1/Key/answerKey.png',
		component: MatchDots,
		titleQuestion: [
			{
				num: '1',
				title: 'Help Jack find his jacket. Connect the correct letters.',
				color: '#23408f',
			},
		],

		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '316px',
							width: 33,
							height: 52,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '407px',
							width: 33,
							height: 52,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '499px',
							width: 33,
							height: 52,
						},
					},

					//j
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '595px',
							width: 37,
							height: 62,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '690px',
							width: 33,
							height: 52,
						},
					},

					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '220px',
							width: 33,
							height: 52,
						},
					},

					//j
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '314px',
							width: 37,
							height: 62,
						},
					},

					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '407px',
							width: 33,
							height: 52,
						},
					},
					//j
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '502px',
							width: 37,
							height: 62,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '596px',
							width: 33,
							height: 52,
						},
					},
					//j
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '690px',
							width: 37,
							height: 62,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '220px',
							width: 33,
							height: 52,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '312px',
							width: 33,
							height: 52,
						},
					},
					//j
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '409px',
							width: 37,
							height: 62,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '505px',
							width: 33,
							height: 52,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '597px',
							width: 33,
							height: 52,
						},
					},
					//i
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '690px',
							width: 33,
							height: 52,
						},
					},
					//jacket
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '149px',
							left: '809px',
							width: 33,
							height: 52,
						},
					},
				],
				answers: ['13-6', '13-8', '3-8', '10-3', '10-17'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page39/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
          <input id='17' type= 'boxMatch' />
      `,
		},
	},
	// 1: {
	//   // Exercise num
	//   unit: "Unit 5",
	//   id: "WB3-U5-P39-E1",
	//   audio: "",
	//   video: "",
	//   exerciseKey: "img/FriendsPlus/Page39/E1/Key/answerKey.png",
	//   component: MatchDots,
	//   titleQuestion: [
	//     {
	//       num: "1",
	//       title: "Help Jack find his jacket. Connect the correct letters.",
	//       color: "#23408f",
	//     },
	//   ],

	//   question: {
	//     DrawLines: {
	//       multipleLine: true,
	//       boxMatch: [
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "50px",
	//             left: "316px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "50px",
	//             left: "407px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "50px",
	//             left: "499px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },

	//         //j
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "50px",
	//             left: "595px",
	//             width: 37,
	//             height: 62,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "50px",
	//             left: "690px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },

	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "150px",
	//             left: "220px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },

	//         //j
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "150px",
	//             left: "314px",
	//             width: 37,
	//             height: 62,
	//           },
	//         },

	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "150px",
	//             left: "407px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //j
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "150px",
	//             left: "502px",
	//             width: 37,
	//             height: 62,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "150px",
	//             left: "596px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //j
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "150px",
	//             left: "690px",
	//             width: 37,
	//             height: 62,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "249px",
	//             left: "220px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "249px",
	//             left: "312px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //j
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "249px",
	//             left: "409px",
	//             width: 37,
	//             height: 62,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "249px",
	//             left: "505px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "249px",
	//             left: "597px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//         //i
	//         {
	//           boxMatchStyle: {
	//             position: "absolute",
	//             top: "249px",
	//             left: "690px",
	//             width: 33,
	//             height: 52,
	//           },
	//         },
	//       ],
	//       answers: ["13-6", "13-8", "3-8", "10-3"],
	//       initialValue: [],
	//     },
	//     Layout: `
	//         <img src='img/FriendsPlus/Page39/E1/1.jpg' />
	//         <input id='0' type= 'boxMatch' />
	//         <input id='1' type= 'boxMatch' />
	//         <input id='2' type= 'boxMatch' />
	//         <input id='3' type= 'boxMatch' />
	//         <input id='4' type= 'boxMatch' />
	//         <input id='5' type= 'boxMatch' />
	//         <input id='6' type= 'boxMatch' />

	//         <input id='7' type= 'boxMatch' />
	//         <input id='8' type= 'boxMatch' />
	//         <input id='9' type= 'boxMatch' />
	//         <input id='10' type= 'boxMatch' />
	//         <input id='11' type= 'boxMatch' />
	//         <input id='12' type= 'boxMatch' />
	//         <input id='13' type= 'boxMatch' />
	//         <input id='14' type= 'boxMatch' />
	//         <input id='15' type= 'boxMatch' />
	//         <input id='16' type= 'boxMatch' />
	//     `,
	//   },
	// },

	2: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P39-E2',
		audio: '',
		video: '',
		component: D1,
		isHiddenCheck: true,
		fontSize: 27,
		padding: 0,
		inputHeight: '35px',
		textAlign: 'center',
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page39/E2/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page39/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page39/E2/3.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page39/E2/4.jpg' },
				{ url: 'img/FriendsPlus/Page39/E2/5.jpg', input: true, answer: 'j' },
				{ url: 'img/FriendsPlus/Page39/E2/6.jpg' },
				{ url: 'img/FriendsPlus/Page39/E2/7.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page39/E2/8.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page39/E2/9.jpg' },
			],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P39-E3',
		audio: '',
		video: '',
		component: D1,
		padding: 0,
		textAlign: 'center',
		isHiddenCheck: true,
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page39/E3/Key/answerKey.png',
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page39/E3/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page39/E3/2.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/3.jpg', input: true, answer: 'j' },
				{ url: 'img/FriendsPlus/Page39/E3/4.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/5.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page39/E3/6.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/7.jpg', input: true, answer: 'l' },
				{ url: 'img/FriendsPlus/Page39/E3/8.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/9.jpg', input: true, answer: 'l' },
				{ url: 'img/FriendsPlus/Page39/E3/10.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/11.jpg', input: true, answer: 'y' },
				{ url: 'img/FriendsPlus/Page39/E3/12.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/13.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page39/E3/14.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/15.jpg', input: true, answer: 'n' },
				{ url: 'img/FriendsPlus/Page39/E3/16.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/17.jpg', input: true, answer: 'k' },
				{ url: 'img/FriendsPlus/Page39/E3/18.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page39/E3/19.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page39/E3/20.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/21.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page39/E3/22.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/23.jpg', input: true, answer: 'l' },
				{ url: 'img/FriendsPlus/Page39/E3/24.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/25.jpg', input: true, answer: 'l' },
				{ url: 'img/FriendsPlus/Page39/E3/26.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/27.jpg', input: true, answer: 'j' },
				{ url: 'img/FriendsPlus/Page39/E3/28.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/29.jpg', input: true, answer: 'a' },
				{ url: 'img/FriendsPlus/Page39/E3/30.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/31.jpg', input: true, answer: 'c' },
				{ url: 'img/FriendsPlus/Page39/E3/32.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/33.jpg', input: true, answer: 'k' },
				{ url: 'img/FriendsPlus/Page39/E3/34.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/35.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page39/E3/36.jpg' },
				{ url: 'img/FriendsPlus/Page39/E3/37.jpg', input: true, answer: 't' },
				{ url: 'img/FriendsPlus/Page39/E3/38.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page39/E3/39.jpg' },
			],
		],
	},
}

export default json
