import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 9',
		id: 'WB3V2-U9-P67-E1',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 200,
		textAlign: 'center',
		exerciseKey: '',
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page67/E1/1.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
         <div style='margin-left: 30px;line-height:50px'>
            <b>1.</b> My hair is#and#. <br>
            <b>2.</b> I’m wearing a#and#. <br>
            <b>3.</b> I can#and#. <br>
         </div>
                `,
				answer: [],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 9',
		id: 'WB3V2-U9-P67-E2',
		audio: '',
		video: '',
		component: DesignUnderLine,
		// component: DesignUnderLine1,
		totalInput: 4,
		exerciseKey: '',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page67/E2/a.jpg' }],
			[{ url: 'img/FriendsPlus/Page67/E2/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page67/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/3.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/4.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/5.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/6.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/7.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/8.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/9.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/10.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page67/E2/11.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/12.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/13.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/14.jpg', input: 6, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/15.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/16.jpg', input: 7, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/17.jpg' },
				{ url: 'img/FriendsPlus/Page67/E2/18.jpg', input: 8, isCorrect: true },
				{ url: 'img/FriendsPlus/Page67/E2/19.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page67/E2/20.jpg' }],
		],
		questions: [
			{
				title: 'The dictionaries are # the shelf',
				answer: ['table'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 9',
		id: 'WB3V2-U9-P67-E3',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		exerciseKey: '',
		inputSize: 200,
		maxLength: 19,
		// titleImage: "img/FriendsPlus/Page67/E2/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page67/E3/1.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <div style='display: flex'>
                  <img src='img/FriendsPlus/Page55/E3/2.jpg' />
                <div style='margin-left: 30px; line-height:45px'>
                    <div> My friend’s name is#.<br>#can#<br>and#.<br>#can’t#<br>#</div>
                </div>
            </div>
        `,
				answer: ['', '', '', '', '', '', ''],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 9',
		id: 'WB3V2-U9-P67-E4',
		audio: '',
		exerciseKey: '',
		video: '',
		recorder: true,
		component: UI,
		// component: DesignUnderLine1,
		titleImage: '',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page67/E4/1.jpg' }],
		],
	},
}

export default json
