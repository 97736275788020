import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P90-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/E1/Key/answerKey.png",
    inputSize: 300,
    maxLength: 22,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 1</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

            
            <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 30px 5px 10px'>
              <b>This is my</b> bag. <br>
              <b>This is your</b> doll.
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Order the words.</b></span>
           <div style='margin:; line-height: 60px'>
              <b>1.</b> is bike. This your &emsp;#<br>
              <b>2.</b> Teddy This my is bear. &emsp;#<br>
           </div>
      `,
        answer: ["This is your bike.", "This is my Teddy bear."],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P90-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/E2/Key/answerKey.png",
    inputSize: 300,
    maxLength: 22,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 2</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

            
            <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 30px 5px 10px'>
              That is <b>his</b> pen. <br>
              That is <b>her</b> pen.
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Order the words.</b></span>
           <div style='margin:; line-height: 60px'>
              <b>1.</b> his is book. That &emsp;#<br>
              <b>2.</b> is That her eraser. &emsp;#<br>
              <b>3.</b> pen. That is her &emsp;#<br>
              <b>4.</b> pencil. his is That &emsp;#<br>
           </div>
      `,
        answer: [
          "That is his book.",
          "That is her eraser.",
          "That is her pen.",
          "That is his pencil.",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P90-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/E3/Key/answerKey.png",
    inputSize: 340,
    maxLength: 30,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 3</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

            
            <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 30px 5px 10px'>
              Her book is <b>in</b> the kitchen. <br>
              His teddy bear is <b>under</b> the slide. <br>
              Their kit is <b>on</b> the chair.
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='margin:; line-height: 60px'>
              <b>1.</b> His / book / on / the bed &emsp;#<br>
              <b>2.</b> Our / car / under / the tree &emsp;#<br>
              <b>3.</b> Her / hat / in / the bag &emsp;#<br>
              <b>4.</b> Their / ball / under / the table &emsp;#<br>
           </div>
      `,
        answer: [
          "His book is on the bed.",
          "Our car is under the tree.",
          "Her hat is in the bag.",
          "Their ball is under the table.",
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P90-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/E4/Key/answerKey.png",
    inputSize: 150,
    maxLength: 10,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 4</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

            
            <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 5px 10px'>
              I <b>like</b> monkeys. <br>
              I <b>don’t like</b> elephants. <br>
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write <i>like</i> or <i>don’t like</i>.</b></span>
           <div style='margin:; line-height: 60px'>
              <b>1.</b> <img src='img/FriendsPlus/Page90/E4/1.jpg' />   I#giraffes.<br>
              <b>2.</b> <img src='img/FriendsPlus/Page90/E4/2.jpg' />  I#elephants.<br>
              <b>3.</b> <img src='img/FriendsPlus/Page90/E4/3.jpg' />  You#monkeys.<br>
           </div>
      `,
        answer: ["like", "like", "don't like"],
      },
    ],
  },
};

export default json;
