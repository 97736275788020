import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P62-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page62/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page62/E1/1.jpg' >",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "75px",
              left: "115px",
              width: 215,
              height: 140,
              // border: "1px solid #2eb6e1",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "115px",
              width: 215,
              height: 140,
              // border: "1px solid #2eb6e1",
            },
          }, //1

          {
            boxMatchStyle: {
              position: "absolute",
              top: "370px",
              left: "115px",
              width: 215,
              height: 130,
              // border: "1px solid #2eb6e1",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "167px",
              left: "372px",
              width: 220,
              height: 140,
              // border: "1px solid #2eb6e1",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "313px",
              left: "372px",
              width: 215,
              height: 130,
              // border: "1px solid #2eb6e1",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "680px",
              width: 60,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "161px",
              left: "670px",
              width: 50,
              height: 20,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //6

          {
            boxMatchStyle: {
              position: "absolute",
              top: "225px",
              left: "710px",
              width: 135,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "290px",
              left: "665px",
              width: 30,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "705px",
              width: 120,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //9
        ],
        answers: ["0-7", "1-8", "2-9", "3-5", "4-6"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page62/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />

          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />

          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P62-E2",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
    inputSize: 153,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page62/E2/Key/answerKey.png",

    // titleImage: "img/FriendsPlus/Page62/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page62/E2/6.jpg' />",
        color: "",
      },
    ],
    maxLength: 11,
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex' >
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page62/E2/1.jpg' />
            <div style='margin-left:100px'><u style="color: gray;">&emsp;fly&emsp;</u></div>
          </div>
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page62/E2/2.jpg' />
            <div style='margin-left:50px'>#</div>
          </div>
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page62/E2/3.jpg' />
            <div style='margin-left:50px'>#</div>
          </div>
        </div>
        <div style='display:flex;margin-top: 20px' >
          <div style='margin-left: 150px'>
            <img src='img/FriendsPlus/Page62/E2/4.jpg' />
            <div style='margin-left:50px'>#</div>
          </div>
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page62/E2/5.jpg' />
            <div style='margin-left:50px'>#</div>
          </div>
        </div>
                `,
        answer: ["ride a bike", "run", "play soccer", "catch"],
      },
    ],
  },
};

export default json;
