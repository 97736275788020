import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P40-E1",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column2
        { url: "img/FriendsPlus/Page40/E1/1.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P40-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page40/E2/Key/answerKey.png",
    component: D1,
    isHiddenCheck:true,
    textAlign:"center",
    fontSize: 23,
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page40/E2/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page40/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page40/E2/3.jpg",
          input: true,
          answer: "Trang",
        },
        { url: "img/FriendsPlus/Page40/E2/4.jpg" },
        { url: "img/FriendsPlus/Page40/E2/5.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page40/E2/6.jpg" },
        { url: "img/FriendsPlus/Page40/E2/7.jpg", input: true, answer: "Huy" },
        { url: "img/FriendsPlus/Page40/E2/8.jpg" },
        { url: "img/FriendsPlus/Page40/E2/9.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page40/E2/10.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page40/E2/11.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page40/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page40/E2/13.jpg",
          input: true,
          answer: "Trang",
        },
        { url: "img/FriendsPlus/Page40/E2/14.jpg" },
        { url: "img/FriendsPlus/Page40/E2/15.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page40/E2/16.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page40/E2/17.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page40/E2/18.jpg" },
        { url: "img/FriendsPlus/Page40/E2/19.jpg", input: true, answer: "Huy" },
        { url: "img/FriendsPlus/Page40/E2/20.jpg" },
        { url: "img/FriendsPlus/Page40/E2/21.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page40/E2/22.jpg" },
        {
          url: "img/FriendsPlus/Page40/E2/23.jpg",
          input: true,
          answer: "Trang",
        },
        { url: "img/FriendsPlus/Page40/E2/24.jpg" },
        { url: "img/FriendsPlus/Page40/E2/25.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page40/E2/26.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page40/E2/27.jpg" },
      ],
    ],
  },
};

export default json;
