import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "WB3V2-U5-P15-E1",
    audio: "",
    video: "",
    component: T6,
    maxLength: 18,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page17/E1/Key/answerKey.png",
    selectStyle: {
      width: 40,
      textAlign: "center",
      // height: 100,
      fontSize: 26,
      backgroundColor: "none",
      border: "2px solid",
    },
    stylesTextInput: { borderBottom: "dotted", width: 240 },
    // selectOptionRandom: true,
    selectOptionValues: ["a", "b", "c", "d"],
    questionImage: [],
    titleImage: "img/FriendsPlus/Page17/E1/1.jpg",
    questions: [
      {
        title: `
              <div style='display:flex;line-height:47px;margin-top:1px'>
                <b>1.</b>
                <div style='margin:0px 200px 0px 10px'>
                  <span style='background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>pencil.</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>his</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>That</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>is</span><br>
                  <span style='display:flex'>
                    #&emsp; <select id='4'></select>
                    </span>
                  </div>
                  <img src="img/FriendsPlus/Page17/E1/2.jpg">
              </div>
              <div style='display:flex;line-height:47px;margin-top:10px'>
                <b>2.</b>
                <div style='margin:0px 200px 0px 10px'>
                  <span style='background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>is</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>That</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>pen.</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>her</span><br>
                  <div style='display:flex'>
                    #&emsp; <select id='5'></select>
                  </div>
                </div>
                  <img src="img/FriendsPlus/Page17/E1/3.jpg">
              </div>
              <div style='display:flex;line-height:47px;margin-top:10px'>
                <b>3.</b>
                <div style='margin:0px 200px 0px 10px'>
                  <span style='background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>her</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>book.</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>is</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>That</span><br>
                  <div style='display:flex'>
                    #&emsp; <select id='6'></select>
                  </div> 
                </div>
                <img src="img/FriendsPlus/Page17/E1/4.jpg">
              </div>
              <div style='display:flex;line-height:47px;margin-top:10px'>
                <b>4.</b>
                <div style='margin:0px 200px 0px 10px'>
                  <span style='background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>his</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>That</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>is</span>
                  <span style='margin-left:10px;background: rgb(215 238 222) ;padding: 7px 12px; border-radius: 10px'>eraser.</span><br>
                  <div style='display:flex'>
                    #&emsp; <select id='7'></select>
                  </div> 
                </div>
                <img src="img/FriendsPlus/Page17/E1/5.jpg">
              </div>

        `,
        answer: [
          "That is his pencil.",
          "That is her pen.",
          "That is her book.",
          "That is his eraser.",
          "b",
          "a",
          "d",
          "c",
        ],
        initialValue: ["a"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P17-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page17/E2/Key/answerKey.png",
    inputSize: 100,
    maxLength: 3,
    textAlign: "center",
    // titleImage: 'img/FriendsPlus/Page13/E2/1.jpg',
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page17/E2/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div><img src='img/FriendsPlus/Page17/E2/2.jpg' /></div>
            <div style='margin-left:30px'>
              
              <div style='margin-bottom: 55px'><b>1.</b> That is <span style='color:gray; text-decoration:underline'> his</span> book.</div>
              <div style='margin-bottom: 55px'><b>2.</b> That is # pencil.</div>
              <div style='margin-bottom: 55px'><b>3.</b> That is #  eraser.</div>
              <div style='margin-bottom: 55px'><b>4.</b> That is # pen.</div>

            </div>

          </div>
          `,
        answer: ["her", "her", "his"],
      },
    ],
  },
};

export default json;
