import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB3V2-U1-P18-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page18/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: " <img src='img/FriendsPlus/Page18/E1/5.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "250px",
              width: 250,
              height: 200,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "800px",
              width: 250,
              height: 200,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "250px",
              width: 300,
              height: 200,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "800px",
              width: 300,
              height: 200,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "530px",
              width: 180,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "140px",
              left: "540px",
              width: 180,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "254px",
              left: "533px",
              width: 180,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "530px",
              width: 180,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "212px",
          //     left: "450px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 8
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "307px",
          //     left: "450px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 9
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "405px",
          //     left: "450px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 10
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "500px",
          //     left: "450px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 12
        ],
        answers: ["0-7", "1-5", "2-4", "3-6"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <div><img src='img/FriendsPlus/Page18/E1/1.jpg' /></div>
        <div><input id='0' type='boxMatch' /></div>
        <div><input id='1' type='boxMatch' /></div>
        <div><input id='2' type='boxMatch' /></div>
        <div><input id='3' type='boxMatch' /></div>
        <div style='font-size: 25px; margin-left: 200px'>
          <div style='margin-bottom: 80px'>Close the door</div>
          <div style='margin-bottom: 80px'>Open the window</div>
          <div style='margin-bottom: 80px'>Close the book</div>
          <div>Open the bag</div>
          <div><input id='4' type='boxMatch' /></div>
          <div><input id='5' type='boxMatch' /></div>
          <div><input id='6' type='boxMatch' /></div>
          <div><input id='7' type='boxMatch' /></div>
        </div>
        <div style='margin-left:100px'><img src='img/FriendsPlus/Page18/E1/2.jpg' /></div>
      </div>
     



      
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P18-E2",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page18/E2/Key/answerKey.png",
    component: D1,
    padding: 0,
    textAlign: "center",
    maxLength: 1,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page18/E2/42.jpg" }],
      [{ url: "img/FriendsPlus/Page18/E2/43.jpg" }],
      [{ url: "img/FriendsPlus/Page18/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E2/2.jpg" },
        { url: "img/FriendsPlus/Page18/E2/3.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page18/E2/4.jpg" },
        { url: "img/FriendsPlus/Page18/E2/5.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page18/E2/6.jpg" },
        { url: "img/FriendsPlus/Page18/E2/7.jpg", input: true, answer: "k" },
        { url: "img/FriendsPlus/Page18/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E2/10.jpg" },
        { url: "img/FriendsPlus/Page18/E2/11.jpg", input: true, answer: "d" },
        { url: "img/FriendsPlus/Page18/E2/12.jpg" },
        { url: "img/FriendsPlus/Page18/E2/13.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page18/E2/14.jpg" },
        { url: "img/FriendsPlus/Page18/E2/15.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page18/E2/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E2/18.jpg" },
        { url: "img/FriendsPlus/Page18/E2/19.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page18/E2/20.jpg" },
        { url: "img/FriendsPlus/Page18/E2/21.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page18/E2/22.jpg" },
        { url: "img/FriendsPlus/Page18/E2/23.jpg", input: true, answer: "d" },
        { url: "img/FriendsPlus/Page18/E2/24.jpg" },
        { url: "img/FriendsPlus/Page18/E2/25.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page18/E2/26.jpg" },
        { url: "img/FriendsPlus/Page18/E2/27.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page18/E2/28.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/29.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E2/30.jpg" },
        { url: "img/FriendsPlus/Page18/E2/31.jpg", input: true, answer: "p" },
        { url: "img/FriendsPlus/Page18/E2/32.jpg" },

        { url: "img/FriendsPlus/Page18/E2/33.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page18/E2/34.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/35.jpg" }],
      [
        { url: "img/FriendsPlus/Page18/E2/36.jpg" },
        { url: "img/FriendsPlus/Page18/E2/37.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page18/E2/38.jpg" },
        { url: "img/FriendsPlus/Page18/E2/39.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page18/E2/40.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/41.jpg" }],
    ],
  },
};

export default json;
