import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P37-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    component: D1,
    fontSize: 25,
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page37/E1/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page37/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/3.jpg",
          input: true,
          answer: "Yes, I do.",
        },
        { url: "img/FriendsPlus/Page37/E1/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page37/E1/5.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page37/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/7.jpg",
          input: true,
          answer: "Do you like carrots?",
        },
        { url: "img/FriendsPlus/Page37/E1/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page37/E1/9.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page37/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/11.jpg",
          input: true,
          answer: "No, I don't.",
        },
        { url: "img/FriendsPlus/Page37/E1/12.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page37/E1/13.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page37/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/15.jpg",
          input: true,
          answer: "Do you like bread?",
        },
        { url: "img/FriendsPlus/Page37/E1/16.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page37/E1/17.jpg" },
      ],
      [
        // Column10
        { url: "img/FriendsPlus/Page37/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/19.jpg",
          input: true,
          answer: "Yes, I do.",
        },
        { url: "img/FriendsPlus/Page37/E1/20.jpg" },
      ],
      [
        // Column11
        { url: "img/FriendsPlus/Page37/E1/21.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P37-E2",
    audio: "",
    video: "",
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page37/E2/Key/answerKey.png",
    component: D1,
    fontSize: 23,
    maxLength: 5,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page37/E2/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page37/E2/2.jpg" },
        { url: "img/FriendsPlus/Page37/E2/3.jpg", input: true, answer: "Do" },
        { url: "img/FriendsPlus/Page37/E2/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page37/E2/5.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page37/E2/6.jpg" },
        { url: "img/FriendsPlus/Page37/E2/7.jpg", input: true, answer: "like" },
        { url: "img/FriendsPlus/Page37/E2/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page37/E2/9.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page37/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page37/E2/11.jpg",
          input: true,
          answer: "don't",
        },
        { url: "img/FriendsPlus/Page37/E2/12.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page37/E2/13.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page37/E2/14.jpg" },
        { url: "img/FriendsPlus/Page37/E2/15.jpg", input: true, answer: "Do" },
        { url: "img/FriendsPlus/Page37/E2/16.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page37/E2/17.jpg" },
      ],
      [
        // Column10
        { url: "img/FriendsPlus/Page37/E2/18.jpg" },
        {
          url: "img/FriendsPlus/Page37/E2/19.jpg",
          input: true,
          answer: "like",
        },
        { url: "img/FriendsPlus/Page37/E2/20.jpg" },
        {
          url: "img/FriendsPlus/Page37/E2/21.jpg",
          input: true,
          answer: "like",
        },
        { url: "img/FriendsPlus/Page37/E2/22.jpg" },
      ],
      [
        // Column11
        { url: "img/FriendsPlus/Page37/E2/23.jpg" },
      ],
      [
        // Column12
        { url: "img/FriendsPlus/Page37/E2/24.jpg" },
        { url: "img/FriendsPlus/Page37/E2/25.jpg", input: true, answer: "do" },
        { url: "img/FriendsPlus/Page37/E2/26.jpg" },
      ],
      [
        // Column13
        { url: "img/FriendsPlus/Page37/E2/27.jpg" },
      ],
    ],
  },
};

export default json;
