import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 7',
		id: 'WB3V2-U7-P53-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page53/E1/Key/answerKey.png',
		component: MatchDots,
		titleImage: "<img src='img/FriendsPlus/Page53/E1/1.jpg'>",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page53/E1/1.jpg'>",
				color: '#23408f',
			},
		],

		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '350px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '457px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '565px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '56px',
							left: '671px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '778px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '244px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '350px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '457px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '565px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '671px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '778px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '244px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//11
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '350px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '457px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//13
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '565px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//14
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '671px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//15
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '778px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//16
				],
				answers: ['0-7', '13-7', '13-14', '14-9', '16-9'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page53/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 7',
		id: 'WB3V2-U7-P53-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page53/E2/Key/answerKey.png',
		component: MatchDots,
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page13/E2/1.jpg'>",
				color: '',
			},
		],
		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '90px',
							left: '120px',
							width: 200,
							height: 158,
							// border: "2px solid red"
						},
					},
					//0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '75px',
							left: '468px',
							width: 140,
							height: 140,
							// border: "2px solid red"
						},
					},
					//1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '85px',
							left: '770px',
							width: 120,
							height: 152,
							// border: "2px solid red"
						},
					},
					//2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '320px',
							left: '120px',
							width: 130,
							height: 152,
							// border: "2px solid red"
						},
					},
					//3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '225px',
							left: '467px',
							width: 140,
							height: 140,
							// border: "2px solid red"
						},
					},
					//4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '380px',
							left: '467px',
							width: 140,
							height: 140,
							// border: "2px solid red"
						},
					},
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '315px',
							left: '795px',
							width: 190,
							height: 135,
							// border: "2px solid red"
						},
					},
				],
				answers: ['0-1', '3-4', '2-5', '1-6'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page53/E2/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
      `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 7',
		id: 'WB3V2-U7-P53-E3',
		isHiddenCheck: true,
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page53/E3/Key/answerKey.png',
		inputSize: 100,
		// titleImage: "img/FriendsPlus/Page53/E4/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page53/E3/1.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex ;margin: 0px 50px'>
          <div style='line-height: 48px; margin-right: 100px'>
            <div>Shirley has # hair.</div>
            <div>Her # are red.</div>
            <div>Her # is blue.</div>
            <div>And she’ sitting on a #.</div>
            <div>Shirley has #sheep,</div>
            <div>One, two, #.</div>
            <div>Shirley wants to play,</div>
            <div>With you and with me.</div>
          </div>
          <img src='img/FriendsPlus/Page53/E3/2.jpg' />

        </div>
        `,
				answer: ['short', 'shoes', 'shirt', 'chair', 'three', 'three'],
			},
		],
	},
}

export default json
