import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P85-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page85/E1/Key/answerKey.png",
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Pag73/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "1",
        title: "Match the word to the letters.",
        // title: "<img src='img/FriendsPlus/Page33/E2/2.jpg'>",
        color: "#224192",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "120px",
              width: 140,
              height: 152,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "330px",
              left: "100px",
              width: 140,
              height: 158,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "107px",
              left: "475px",
              width: 155,
              height: 70,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "181px",
              left: "475px",
              width: 155,
              height: 70,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "760px",
              width: 140,
              height: 150,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "315px",
              left: "475px",
              width: 145,
              height: 140,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "106px",
              left: "770px",
              width: 150,
              height: 160,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "770px",
              width: 150,
              height: 160,
              // border: "2px solid red",
              // background: 'purple',
            },
          },
        ],
        answers: ["0-5", "1-3", "5-6", "2-7"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page85/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P85-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page85/E2/Key/answerKey.png",
    inputSize: 40,
    textAlign: "center",
    maxLength: 1,
    titleImage: "img/FriendsPlus/Page85/E2/5.jpg",
    titleQuestion: [{ num: "", title: "", color: "" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex; margin: 10px 50px'>
          <div style='margin:'>
            <img src='img/FriendsPlus/Page85/E2/1.jpg' />
            <div style='margin:5px 0px 0px 70px'>#ou##</div>
          </div>
          <div style='margin:0px 100px'>
            <img src='img/FriendsPlus/Page85/E2/2.jpg' />
            <div style='margin:5px 70px'>#ou##</div>
          </div>
        </div>
        <div style='display:flex; margin: 10px 50px'>
          <div style='margin:'>
            <img src='img/FriendsPlus/Page85/E2/3.jpg' />
            <div style='margin:5px 0px 0px 99px'>#oor</div>
          </div>
          <div style='margin:0px 100px'>
            <img src='img/FriendsPlus/Page85/E2/4.jpg' />
            <div style='margin:5px 96px'>#our</div>
          </div>
        </div>
        
        `,
        answer: ["m", "s", "e", "h", "s", "e", "p", "t"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P85-E3",
    audio: "Audios/Track 20/audio.mp3",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and write. <headphone name='20' src='Audios/Track 20/tieude.mp3' ></headphone>",
        color: "#233f94",
      },
    ],
    component: T6,
    exerciseKey: "img/FriendsPlus/Page85/E3/Key/answerKey.png",
    inputSize: 120,
    textAlign: "center",
    maxLength: 5,

    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src='img/FriendsPlus/Page85/E3/2.jpg' />

          <div style='margin-top: 100px; line-height:55px'>
              I’m sure there’a <sup>1</sup><u style="color: gray;">&emsp;mouse&emsp;</u> in my <sup>2</sup>#.<br>
              A <sup>3</sup>#mouse in my <sup>4</sup>#.<br> He’s on a <sup>5</sup>#, I’m sure.
              A <sup>6</sup># <br> around my <sup>7</sup>#.
          </div>
        </div>
        
        `,
        answer: ["house", "poor", "house", "tour", "tour", "house"],
      },
    ],
  },
};

export default json;
