import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P80-E1",
    audio: "",
    video: "",
    component: UI,
    titleImage: "",
    recorder:true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page80/E1/2.jpg" }],
      [{ url: "img/FriendsPlus/Page80/E1/1.jpg" }],
      
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P80-E2",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page80/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page80/E2/25.jpg" }],
      [{ url: "img/FriendsPlus/Page80/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page80/E2/2.jpg" },
        { url: "img/FriendsPlus/Page80/E2/3.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page80/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page80/E2/5.jpg" },
        { url: "img/FriendsPlus/Page80/E2/6.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page80/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page80/E2/8.jpg" },
        { url: "img/FriendsPlus/Page80/E2/9.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page80/E2/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page80/E2/11.jpg" },
        { url: "img/FriendsPlus/Page80/E2/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page80/E2/13.jpg" },
        { url: "img/FriendsPlus/Page80/E2/14.jpg", input: 3, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page80/E2/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page80/E2/16.jpg" },
        { url: "img/FriendsPlus/Page80/E2/17.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page80/E2/18.jpg" },
        { url: "img/FriendsPlus/Page80/E2/19.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page80/E2/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page80/E2/21.jpg" },
        { url: "img/FriendsPlus/Page80/E2/22.jpg", input: 2 },
        { url: "img/FriendsPlus/Page80/E2/23.jpg" },
        { url: "img/FriendsPlus/Page80/E2/24.jpg", input: 3 },
      ],
    ],
  },
};

export default json;
