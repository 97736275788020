import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P84-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page84/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page84/E1/a.jpg'>",
        color: "",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: { color: "black", textAlign:"center" },
        answers: ["picture","drawers","poster"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          fontSize: 30,
          borderRadius: "20px",
        },
        selectWordStyle: { border: "2px solid #2eb6e1" },
        limitSelect: 3,
        listWords: ["picture|drawers|poster"],
        answers: ['0-0', '0-2', '0-4'],
        initialValue: [],
      },
      Layout: `
      
      <div style='display:flex'>
        <div style='margin-right:100px'>
          <img src='img/FriendsPlus/Page84/E1/1.jpg'>
          <div><input id='0' width='4cm'></input></div>
        </div>
        <div>
          <input id='0' type='Circle' />
            <div style='display:flex; margin-top:37px'>
                <div style='margin-right:100px'>
                  <img src='img/FriendsPlus/Page84/E1/2.jpg'>
                  <div style='margin-left:22px'><input id='1' width='4cm'></input></div>
                </div>
                <div>
                  <img src='img/FriendsPlus/Page84/E1/3.jpg'>
                  <div style='margin-left:13px'><input id='2' width='4cm'></input></div>
                </div>
            </div>
        </div>

      </div>
        
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P84-E2",
    audio: "",
    video: "",
    component: T6,
    // checkUppercase: true,
    selectStyle: {
      color: "#00ADFE",
      width: 36,
      height: 45,
      textAlign: "center",
    },
    selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page84/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page84/E2/1.jpg' />",
        color: "",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page84/E2/2.jpg' /> </div>
						<div style=" position: absolute; top:153px; left: 765px; "><select id='0'></select></div>
						<div style=" position: absolute; top: 390px; left: 368px; "><select id='1'></select></div>
						<div style=" position: absolute; top: 390px; left: 752px; "><select id='2'></select></div>
						<div style=" position: absolute; top: 630px; left: 289px; "><select id='3'></select></div>
						<div style=" position: absolute; top: 630px; left: 796px; "><select id='4'></select></div>
					</div>
				`,
        answer: ["✓", "✓", "✗", "✗", "✗"],
      },
    ],
  },
};

export default json;
