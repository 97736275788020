import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 12',
		id: 'WB3V2-U12-P82-E1',
		audio: '',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page83/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page82/E1/1.jpg' >",
				color: '',
			},
		],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '130px',
							left: '180px',
							width: 340,
							height: 168,
							// border: "1px solid #2eb6e1",
						},
					}, //0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '120px',
							left: '770px',
							width: 155,
							height: 90,
							// border: "1px solid #2eb6e1",
						},
					}, //1

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '98px',
							left: '480px',
							width: 70,
							height: 25,
							// border: "1px solid #2eb6e1",
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '480px',
							width: 75,
							height: 25,
							// border: "1px solid #2eb6e1",
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '163px',
							left: '602px',
							width: 70,
							height: 25,
							border: '1px solid #2eb6e1',
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '222px',
							left: '602px',
							width: 70,
							height: 25,
							border: '1px solid #2eb6e1',
							borderRadius: '50px',
						},
					}, //5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '282px',
							left: '602px',
							width: 70,
							height: 25,
							border: '1px solid #2eb6e1',
							borderRadius: '50px',
						},
					}, //6

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '342px',
							left: '625px',
							width: 120,
							height: 25,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '300px',
							left: '365px',
							width: 60,
							height: 30,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '367px',
							left: '365px',
							width: 60,
							height: 30,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //9
				],
				answers: ['0-2', '1-3'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page83/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />

          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />

      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 12',
		id: 'WB3V2-U12-P83-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page83/E2/Key/answerKey.png',
		inputSize: 150,
		titleImage: 'img/FriendsPlus/Page83/E2/3.jpg',
		maxLength: 5,
		textAlign: 'center',
		// titleQuestion: [
		//   {
		//     num: "2",
		//     title:
		//       "Look and read. Choose the correct answer and write it on the line.",
		//     color: "#5B5A5D",
		//   },
		// ],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
          <div>
            <img src='img/FriendsPlus/Page83/E2/1.jpg' />
            <div style='margin:'>
              <b>1.</b> <u style="color: gray;">&emsp;those&emsp;</u>   
              <span style='margin-left:50px'><b>2.</b> #</span>       
            </div>
          </div>
          <div>
            <img src='img/FriendsPlus/Page83/E2/2.jpg' />
            <div style='margin:'>
              <b>3.</b> #  
              <span style='margin-left:50px'><b>4.</b> #</span>       
            </div>
          </div>
        </div>
        `,
				answer: ['these', 'those', 'these'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 12',
		id: 'WB3V2-U12-P83-E3',
		audio: '',
		video: '',
		component: D1,
		totalInput: 3,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page83/E3/Key/answerKey.png',
		titleImage: 'img/FriendsPlus/Page83/E3/9.jpg',
		maxLength: 9,
		fontSize: 28,
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page83/E3/9.jpg' }],
			[{ url: 'img/FriendsPlus/Page83/E3/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page83/E3/2.jpg' },
				{
					url: 'img/FriendsPlus/Page83/E3/3.jpg',
					input: true,
					answer: 'Those are',
				},
				{ url: 'img/FriendsPlus/Page83/E3/4.jpg' },
				{
					url: 'img/FriendsPlus/Page83/E3/5.jpg',
					input: true,
					answer: 'These are',
				},
				{ url: 'img/FriendsPlus/Page83/E3/6.jpg' },
				{
					url: 'img/FriendsPlus/Page83/E3/7.jpg',
					input: true,
					answer: 'Those are',
				},
			],
			[{ url: 'img/FriendsPlus/Page83/E3/8.jpg' }],
		],
	},
}

export default json
